import React, { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { submitReview, hasUserReviewedWorker, getUserProfile } from '../utils/firestoreOperations';
import {logger} from '../utils/logger';

const MAX_COMMENT_LENGTH = 500;
const MIN_COMMENT_LENGTH = 10;

const ReviewForm = ({ userId, onReviewSubmitted }) => {
  const [rating, setRating] = useState(5);
  const [comment, setComment] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState('');
  const [hasReviewed, setHasReviewed] = useState(false);
  const [commentError, setCommentError] = useState('');
  const [reviewerFirstName, setReviewerFirstName] = useState('');
  const { currentUser } = useAuth();

  useEffect(() => {
    const checkExistingReview = async () => {
      if (currentUser && userId) {
        try {
          const reviewed = await hasUserReviewedWorker(userId, currentUser.uid);
          setHasReviewed(reviewed);

          // Fetch the current user's profile to get the firstName
          const userProfile = await getUserProfile(currentUser.uid);
          setReviewerFirstName(userProfile?.firstName || 'Anonymous');
        } catch (error) {
          logger.error('Error checking for existing review or fetching user profile:', error);
        }
      }
    };

    checkExistingReview();
  }, [currentUser, userId]);

  const sanitizeComment = (text) => {
    let sanitized = text.replace(/<[^>]*>?/gm, '');
    sanitized = sanitized
      .replace(/[^\w\s.,!?-]/g, '')
      .replace(/(\w)\1{4,}/g, '$1$1$1$1')
      .trim();
    sanitized = sanitized.replace(/(\w{15})(\w+)/g, '$1 $2');
    sanitized = sanitized.replace(/(\S{20})(\S+)/g, '$1 $2');
    sanitized = sanitized.replace(/\d{10,}/g, '');
    const maxWords = 50;
    sanitized = sanitized.split(/\s+/).slice(0, maxWords).join(' ');
    return sanitized.slice(0, MAX_COMMENT_LENGTH);
  };

  const validateComment = (text) => {
    const sanitized = sanitizeComment(text);
    if (sanitized.length < MIN_COMMENT_LENGTH) {
      return `Comment must be at least ${MIN_COMMENT_LENGTH} characters long.`;
    }
    if (sanitized.length > MAX_COMMENT_LENGTH) {
      return `Comment must not exceed ${MAX_COMMENT_LENGTH} characters.`;
    }
    return '';
  };

  const handleCommentChange = (e) => {
    const newComment = e.target.value;
    setComment(newComment);
    setCommentError(validateComment(newComment));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!currentUser) {
      setError('You must be logged in to submit a review');
      return;
    }
    if (currentUser.uid === userId) {
      setError('You cannot review your own profile');
      return;
    }
    if (commentError) {
      return;
    }

    setIsSubmitting(true);
    setError('');

    try {
      const sanitizedComment = sanitizeComment(comment);
      await submitReview(userId, currentUser.uid, rating, sanitizedComment, reviewerFirstName);
      logger.log('Review submitted successfully');
      setComment('');
      setRating(5);
      setHasReviewed(true);
      onReviewSubmitted();
    } catch (error) {
      logger.error('Error submitting review:', error);
      setError(error.message || 'Failed to submit review. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };


  if (hasReviewed || (currentUser && currentUser.uid === userId)) {
    return null;
  }

  return (
    <form onSubmit={handleSubmit} className="mt-4">
      <h3 className="text-lg font-semibold mb-2">Submit a Review</h3>
      <div className="mb-2">
        <label className="block">Rating:</label>
        <select
          value={rating}
          onChange={(e) => setRating(Number(e.target.value))}
          className="w-full p-2 border rounded"
        >
          {[1, 2, 3, 4, 5].map((value) => (
            <option key={value} value={value}>
              {value} Star{value !== 1 ? 's' : ''}
            </option>
          ))}
        </select>
      </div>
      <div className="mb-2">
        <label className="block">Comment:</label>
        <textarea
          value={comment}
          onChange={handleCommentChange}
          className={`w-full p-2 border rounded ${commentError ? 'border-red-500' : ''}`}
          rows="3"
        ></textarea>
        {commentError && <p className="text-red-500 text-sm mt-1">{commentError}</p>}
        <p className="text-sm text-gray-500 mt-1">
          {comment.length}/{MAX_COMMENT_LENGTH} characters
        </p>
      </div>
      {error && <p className="text-red-500 mb-2">{error}</p>}
      <button
        type="submit"
        disabled={isSubmitting || !!commentError}
        className="w-full p-2 bg-blue-500 text-white rounded disabled:bg-gray-300"
      >
        {isSubmitting ? 'Submitting...' : 'Submit Review'}
      </button>
    </form>
  );
};

export default ReviewForm;