import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Search, MapPin, Briefcase, Users, MessageCircle, AlertCircle } from 'lucide-react';
import colombianCities from '../data/colombianCities';
import skills from '../data/skills';
import specializations from '../data/specializations';
import { homeTranslations } from '../translations';
import {logger} from '../utils/logger';

const Home = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCity, setSelectedCity] = useState('');
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [showError, setShowError] = useState(false);
  const navigate = useNavigate();
  const searchRef = useRef(null);

  const filteredItems = useMemo(() => {
    const lowercaseSearchTerm = searchTerm.toLowerCase();
    return [...new Set([...skills, ...specializations])].filter(item => 
      item.toLowerCase().includes(lowercaseSearchTerm)
    );
  }, [searchTerm]);

  const handleSearch = () => {
    if (!searchTerm.trim()) {
      setShowError(true);
      return;
    }

    const params = new URLSearchParams();
    params.append('term', searchTerm.trim());
    if (selectedCity) params.append('city', selectedCity);
    navigate(`/search?${params.toString()}`);
  };

  const handleItemSelect = (item) => {
    logger.log('Item selected:', item);
    setSearchTerm(item);
    setShowSuggestions(false);
    setShowError(false);
  };

  const handleClickOutside = (event) => {
    if (searchRef.current && !searchRef.current.contains(event.target)) {
      setShowSuggestions(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    setShowSuggestions(value.trim() !== '');
    if (value.trim()) {
      setShowError(false);
    }
  };

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      {/* Hero Section */}
      <div className="text-center mb-16">
        <h1 className="text-4xl font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
          {homeTranslations.hero.title}
        </h1>
        <p className="mt-3 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
          {homeTranslations.hero.subtitle}
        </p>
      </div>

      {/* Search Section */}
      <div className="bg-white rounded-lg shadow-lg p-6 mb-12">
        <div className="flex flex-col md:flex-row gap-4">
          <div className="relative flex-grow" ref={searchRef}>
            <input
              type="text"
              value={searchTerm}
              onChange={handleSearchChange}
              placeholder={homeTranslations.search.inputPlaceholder}
              className={`w-full p-4 pl-12 pr-10 border ${showError ? 'border-red-500' : 'border-gray-300'} rounded-lg focus:outline-none focus:ring-2 ${showError ? 'focus:ring-red-500' : 'focus:ring-blue-500'} shadow-sm text-lg`}
            />
            <Search className={`absolute left-4 top-1/2 transform -translate-y-1/2 ${showError ? 'text-red-400' : 'text-gray-400'}`} size={24} />
            {showError && (
              <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                <AlertCircle className="h-5 w-5 text-red-500" aria-hidden="true" />
              </div>
            )}
            {showSuggestions && filteredItems.length > 0 && (
              <ul className="absolute z-10 w-full bg-white border border-gray-300 mt-1 max-h-60 overflow-auto rounded-md shadow-lg">
                {filteredItems.map((item, index) => (
                  <li 
                    key={`${item}-${index}`}
                    onClick={() => handleItemSelect(item)}
                    className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                  >
                    {item}
                  </li>
                ))}
              </ul>
            )}
          </div>

          <div className="relative md:w-1/3">
            <select
              value={selectedCity}
              onChange={(e) => setSelectedCity(e.target.value)}
              className="w-full p-4 pl-12 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 shadow-sm appearance-none text-lg"
            >
              <option value="">{homeTranslations.search.cityPlaceholder}</option>
              {colombianCities.map((city) => (
                <option key={city} value={city}>{city}</option>
              ))}
            </select>
            <MapPin className="absolute left-4 top-1/2 transform -translate-y-1/2 text-gray-400" size={24} />
          </div>

          <button
            onClick={handleSearch}
            className="px-8 py-4 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition duration-300 text-lg font-semibold flex items-center justify-center"
          >
            <Search className="mr-2" size={24} />
            {homeTranslations.search.button}
          </button>
        </div>
        {showError && (
          <p className="mt-2 text-sm text-red-600" id="email-error">
            {homeTranslations.search.errorMessage}
          </p>
        )}
      </div>

      {/* How It Works Section */}
      <div className="py-12 bg-gray-50 rounded-lg">
        <div className="max-w-xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8">
          <h2 className="sr-only">{homeTranslations.howItWorks.title}</h2>
          <div className="grid grid-cols-1 gap-8 lg:grid-cols-3">
            <div className="text-center">
              <div className="flex items-center justify-center h-12 w-12 rounded-md bg-blue-500 text-white mx-auto">
                <Briefcase className="h-6 w-6" aria-hidden="true" />
              </div>
              <h3 className="mt-6 text-lg font-medium text-gray-900">{homeTranslations.howItWorks.find.title}</h3>
              <p className="mt-2 text-base text-gray-500">
                {homeTranslations.howItWorks.find.description}
              </p>
            </div>

            <div className="text-center">
              <div className="flex items-center justify-center h-12 w-12 rounded-md bg-blue-500 text-white mx-auto">
                <Users className="h-6 w-6" aria-hidden="true" />
              </div>
              <h3 className="mt-6 text-lg font-medium text-gray-900">{homeTranslations.howItWorks.connect.title}</h3>
              <p className="mt-2 text-base text-gray-500">
                {homeTranslations.howItWorks.connect.description}
              </p>
            </div>

            <div className="text-center">
              <div className="flex items-center justify-center h-12 w-12 rounded-md bg-blue-500 text-white mx-auto">
                <MessageCircle className="h-6 w-6" aria-hidden="true" />
              </div>
              <h3 className="mt-6 text-lg font-medium text-gray-900">{homeTranslations.howItWorks.collaborate.title}</h3>
              <p className="mt-2 text-base text-gray-500">
                {homeTranslations.howItWorks.collaborate.description}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;