import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Search, MapPin, X, Loader, DollarSign, Clock, MapPin as LocationPin } from 'lucide-react';
import { searchWorkers } from '../utils/firestoreOperations';
import colombianCities from '../data/colombianCities';
import skills from '../data/skills';
import specializations from '../data/specializations';
import { Link } from 'react-router-dom';
import { translations } from '../translations';
import { logAnalyticsEvent } from '../utils/analytics';
import {logger} from '../utils/logger';

const formatCurrency = (amount) => {
  if (amount == null || isNaN(amount)) {
    return translations.workerCard.notSpecified;
  }
  return new Intl.NumberFormat('es-CO', {
    style: 'currency',
    currency: 'COP',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  }).format(amount).replace('$', 'COP ');
};

const SearchComponent = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCity, setSelectedCity] = useState('');
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const location = useLocation();
  const navigate = useNavigate();
  const searchRef = useRef(null);

  const filteredItems = [...skills, ...specializations].filter(item => 
    item.toLowerCase().includes(searchTerm.toLowerCase())
  );

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const term = params.get('term') || '';
    const city = params.get('city') || '';

    setSearchTerm(term);
    setSelectedCity(city);

    if (term || city) {
      handleSearch(term, city);
    }
  }, [location]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (searchRef.current && !searchRef.current.contains(event.target)) {
      setShowSuggestions(false);
    }
  };

  const handleItemSelect = (item) => {
    setSearchTerm(item);
    setShowSuggestions(false);
    updateSearch(item, selectedCity);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setShowSuggestions(true);
  };

  const handleCityChange = (e) => {
    const city = e.target.value;
    setSelectedCity(city);
    updateSearch(searchTerm, city);
  };

  const updateSearch = (term, city) => {
    const params = new URLSearchParams();
    if (term) params.append('term', term);
    if (city) params.append('city', city);

    navigate(`/search?${params.toString()}`, { replace: true });
    handleSearch(term, city);
  };

  const handleSearch = useCallback(async (term = searchTerm, city = selectedCity) => {
    logger.log('Performing search with:', { term, city });
    setIsLoading(true);
    setError(null);
    setSearchResults([]);

    try {
      const workers = await searchWorkers(term, city);
      logger.log("Search results received:", workers);
      setSearchResults(workers);

      // Log search event
      logAnalyticsEvent('search_performed', {
        search_term: term,
        city: city,
        results_count: workers.length
      });

    } catch (err) {
      logger.error("Error in handleSearch:", err);
      setError(`${translations.error.searchFailed}: ${err.message}. ${translations.error.tryAgain}`);
    } finally {
      setIsLoading(false);
    }
  }, [searchTerm, selectedCity]);

  const handleSubmit = (e) => {
    e.preventDefault();
    updateSearch(searchTerm, selectedCity);
  };

  const handleProfileView = (worker) => {
    logAnalyticsEvent('search_result_profile_view', {
      worker_id: worker.id,
      worker_skill: worker.skill,
      worker_city: worker.city,
      search_term: searchTerm,
      search_city: selectedCity
    });
  };

  const getImageUrl = (photoUrl) => {
    if (photoUrl && photoUrl.startsWith('http')) {
      return photoUrl;
    } else if (photoUrl) {
      return `${process.env.PUBLIC_URL}/${photoUrl}`;
    }
    return `${process.env.PUBLIC_URL}/default-avatar.png`;
  };

  

  return (
    <div className="p-4 bg-gray-100 min-h-screen">
      <form onSubmit={handleSubmit} className="mb-6">
        <div className="flex flex-col sm:flex-row gap-2">
          <div className="relative flex-grow" ref={searchRef}>
            <input
              type="text"
              value={searchTerm}
              onChange={handleSearchChange}
              onFocus={() => setShowSuggestions(true)}
              placeholder={translations.search.placeholder}
              className="w-full py-2 pl-8 pr-3 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500 text-sm"
            />
            <Search className="absolute left-2 top-1/2 transform -translate-y-1/2 text-gray-400" size={18} />
            {searchTerm && (
              <button
                type="button"
                onClick={() => setSearchTerm('')}
                className="absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-400 hover:text-gray-600"
                aria-label={translations.search.clearSearch}
              >
                <X size={16} />
              </button>
            )}
            {showSuggestions && filteredItems.length > 0 && (
              <ul className="absolute z-10 w-full bg-white border border-gray-300 mt-1 max-h-48 overflow-auto rounded-md shadow-lg">
                {filteredItems.map((item, index) => (
                  <li 
                    key={`${item}-${index}`}
                    onClick={() => handleItemSelect(item)}
                    className="px-3 py-1 hover:bg-gray-100 cursor-pointer text-sm"
                  >
                    {item}
                  </li>
                ))}
              </ul>
            )}
          </div>
          <div className="relative sm:w-1/3">
            <select
              value={selectedCity}
              onChange={handleCityChange}
              className="w-full py-2 pl-8 pr-3 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500 appearance-none text-sm"
            >
              <option value="">{translations.search.cityPlaceholder}</option>
              {colombianCities.map((city) => (
                <option key={city} value={city}>{city}</option>
              ))}
            </select>
            <MapPin className="absolute left-2 top-1/2 transform -translate-y-1/2 text-gray-400" size={18} />
          </div>
          <button
            type="submit"
            className="py-2 px-4 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition duration-300 text-sm font-medium flex items-center justify-center"
          >
            <Search className="mr-1" size={18} />
            {translations.search.button}
          </button>
        </div>
      </form>

      {isLoading ? (
        <div className="flex justify-center items-center h-64">
          <Loader className="animate-spin text-blue-500" size={48} />
          <span className="ml-2">{translations.loading}</span>
        </div>
      ) : error ? (
        <div className="text-red-500 text-center p-4 bg-red-100 rounded-lg">
          <p>{error}</p>
          <p className="mt-2 text-sm">{translations.error.contactSupport}</p>
        </div>
      ) : (
        <div>
          <h2 className="text-2xl font-bold mb-6 text-gray-800">{translations.search.results}</h2>
          {searchResults.length === 0 ? (
            <div className="text-center py-12 bg-white rounded-lg shadow">
              <Search className="mx-auto text-gray-400 mb-4" size={48} />
              <p className="text-xl text-gray-600">{translations.search.noResults}</p>
            </div>
          ) : (
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
              {searchResults.map((worker) => (
                <div key={worker.id} className="bg-white rounded-lg overflow-hidden shadow-lg hover:shadow-xl transition-shadow duration-300">
                  <div className="relative h-48">
                    <img
                      src={getImageUrl(worker.photoUrl)}
                      alt={`${worker.firstName}'s profile`}
                      className="absolute h-full w-full object-cover"
                      onError={(e) => {
                        console.warn(`Failed to load image for worker ${worker.id}. Falling back to default avatar.`);
                        e.target.src = `${process.env.PUBLIC_URL}/default-avatar.png`;
                      }}
                    />
                  </div>
                  <div className="p-4">
                    <h3 className="font-bold text-xl mb-2 text-gray-800">{worker.firstName}</h3>
                    <p className="text-blue-600 font-semibold mb-2">{worker.skill || translations.workerCard.skillNotSpecified}</p>
                    <div className="mb-3">
                      <div className="flex flex-wrap gap-1">
                        {Array.isArray(worker.tags) && worker.tags.length > 0 ? (
                          worker.tags.slice(0, 3).map((tag, index) => (
                            <span key={`${worker.id}-${tag}-${index}`} className="inline-block bg-blue-100 text-blue-800 text-xs px-2 py-1 rounded-full">
                              {tag}
                            </span>
                          ))
                        ) : (
                          <span className="text-gray-500 text-sm">{translations.workerCard.noSpecializations}</span>
                        )}
                      </div>
                    </div>
                    <div className="flex items-center mb-2">
                      <LocationPin size={16} className="text-gray-500 mr-1" />
                      <span className="text-gray-600">{worker.city || translations.workerCard.cityNotSpecified}</span>
                    </div>
                    <div className="flex items-center mb-2">
                      <Clock size={16} className="text-gray-500 mr-1" />
                      <span className="text-gray-600">
                        {worker.experience 
                          ? translations.workerCard.experienceYears(worker.experience)
                          : translations.workerCard.experienceNotSpecified}
                      </span>
                    </div>
                    <div className="flex items-center mb-3">
                      <DollarSign size={16} className="text-gray-500 mr-1" />
                      <span className="text-gray-600">{formatCurrency(worker.hourlyRate)} {translations.workerCard.hourlyRate}</span>
                    </div>
                    <Link 
                      to={`/profile/${worker.id}`} 
                      className="block w-full text-center bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition duration-300 text-sm font-semibold"
                      onClick={() => handleProfileView(worker)}
                    >
                      {translations.workerCard.viewProfile}
                    </Link>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default SearchComponent;