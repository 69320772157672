import { 
  collection, 
  addDoc, 
  query, 
  where, 
  getDocs, 
  doc,
  getDoc,
  setDoc,
  updateDoc,
  deleteDoc,
  deleteField,
  serverTimestamp,
  limit 
} from 'firebase/firestore';
import { db } from '../firebase';
import { sendErrorToTelegram } from './telegramErrorLogger';
import {logger} from '../utils/logger';

/**
 * Sanitizes and validates input data for user profiles
 * @param {Object} data - The input data to sanitize and validate
 * @returns {Object} - The sanitized and validated data
 */
const sanitizeAndValidate = (data) => {
  // Helper function to sanitize strings
  const sanitizeString = (str, maxLength) => {
    if (typeof str !== 'string') return '';
    return str.trim().slice(0, maxLength);
  };

  // Helper function to sanitize and validate numeric fields
  const sanitizeNumber = (value, min, max) => {
    const num = Number(value);
    if (isNaN(num)) return null;
    return Math.min(Math.max(num, min), max);
  };

  return {
    firstName: sanitizeString(data.firstName, 50),
    lastName: sanitizeString(data.lastName, 50),
    introduction: sanitizeString(data.introduction, 500),
    city: sanitizeString(data.city, 50),
    skill: sanitizeString(data.skill, 50),
    tags: Array.isArray(data.tags) 
      ? data.tags.slice(0, 10).map(tag => sanitizeString(tag, 30)) 
      : [],
    experience: sanitizeNumber(data.experience, 0, 100),
    hourlyRate: sanitizeNumber(data.hourlyRate, 0, 10000000),
    email: sanitizeString(data.email, 100),
    phoneNumber: sanitizeString(data.phoneNumber, 20),
    contactPreference: ['reveal', 'chat'].includes(data.contactPreference) ? data.contactPreference : 'reveal',
    photoUrl: sanitizeString(data.photoUrl, 500),
    isWorker: Boolean(data.isWorker)
  };
};

/**
 * Creates or updates a user profile in Firestore
 * @param {string} userId - The ID of the user
 * @param {Object} profileData - The profile data to save
 * @returns {Promise<void>}
 */
export const createOrUpdateUserProfile = async (userId, profileData) => {
  if (!userId) {
    throw new Error('User ID is required');
  }

  try {
    // Sanitize and validate the input data
    const validatedData = sanitizeAndValidate(profileData);

    // Reference to the document
    const docRef = doc(db, 'users', userId);

    // Check if the document already exists
    const docSnap = await getDoc(docRef);
    const exists = docSnap.exists();

    // Prepare the data to save
    let dataToSave = {
      ...validatedData,
      updatedAt: serverTimestamp() // Add a timestamp for when the profile was last updated
    };

    // If the document doesn't exist, add the createdAt field
    if (!exists) {
      dataToSave.createdAt = serverTimestamp();
    }

    // Handle removal of worker-specific fields if user is not a worker
    if (!validatedData.isWorker) {
      const fieldsToRemove = ['lastName', 'introduction', 'city', 'skill', 'tags', 'experience', 'hourlyRate', 'contactPreference', 'photoUrl'];
      fieldsToRemove.forEach(field => {
        if (field in dataToSave) {
          dataToSave[field] = deleteField();
        }
      });
    }

    logger.log('Data to be saved:', dataToSave); // Debug log

    // Update the document in Firestore
    await setDoc(docRef, dataToSave, { merge: true });

    logger.log(`Profile ${exists ? 'updated' : 'created'} successfully for userId:`, userId);
  } catch (error) {
    logger.error('Error creating/updating profile:', error);
    await sendErrorToTelegram('Error in createOrUpdateUserProfile', { userId, error: error.message });
    throw error;
  }
};
/**
 * Retrieves a user profile by user ID
 * @param {string} userId - The ID of the user
 * @returns {Promise<Object|null>} - The user profile or null if not found
 */
export const getUserProfileByUserId = async (userId) => {
  logger.log('Fetching profile for userId:', userId);
  if (!userId) {
    logger.error('getUserProfileByUserId called with undefined userId');
    return null;
  }

  try {
    const docRef = doc(db, 'users', userId);
    const docSnap = await getDoc(docRef);
    
    if (docSnap.exists()) {
      const userData = docSnap.data();
      logger.log('User data fetched:', userData);
      return { id: docSnap.id, ...userData };
    } else {
      logger.log('No profile found for userId:', userId);
      return null;
    }
  } catch (error) {
    logger.error('Error getting profile:', error);
    await sendErrorToTelegram('Error in getUserProfileByUserId', { error: 'Undefined userId' });
    throw error;
  }
};

/**
 * Retrieves a user profile without sensitive information
 * @param {string} userId - The ID of the user
 * @returns {Promise<Object|null>} - The public user profile or null if not found
 */
export const getUserProfileWithHiddenInfo = async (userId) => {
  try {
    const docRef = doc(db, 'users', userId);
    const docSnap = await getDoc(docRef);
    
    if (docSnap.exists()) {
      const data = docSnap.data();
      // Remove sensitive information
      const { email, phone, ...publicProfile } = data;
      return { id: docSnap.id, ...publicProfile };
    } else {
      logger.log('No profile found for userId:', userId);
      return null;
    }
  } catch (error) {
    logger.error('Error getting profile with hidden info:', error);
    await sendErrorToTelegram('Error in getUserProfileWithHiddenInfo', { userId, error: error.message });
    throw error;
  }
};

/**
 * Searches for workers based on skill, specialization, general term, and city
 * @param {string} searchTerm - The term to search for
 * @param {string} city - The city to search in
 * @returns {Promise<Array>} - An array of matching worker profiles
 */
export const searchWorkers = async (searchTerm, city) => {
  logger.log('searchWorkers called with:', { searchTerm, city });
  try {
    const usersRef = collection(db, 'users');
    let q = query(usersRef, where('isWorker', '==', true));

    // Apply city filter if provided
    if (city && city !== "") {
      q = query(q, where('city', '==', city));
    }

    logger.log('Executing Firestore query...');
    const snapshot = await getDocs(q);
    logger.log('Query executed. Number of documents:', snapshot.size);

    let workers = snapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data(),
    }));

    logger.log('Workers before filtering:', workers.length);

    // Client-side filtering for search term
    if (searchTerm && searchTerm !== "") {
      const lowercaseSearchTerm = searchTerm.toLowerCase();
      workers = workers.filter(worker => 
        (worker.skill && worker.skill.toLowerCase().includes(lowercaseSearchTerm)) ||
        (Array.isArray(worker.tags) && worker.tags.some(tag => tag.toLowerCase().includes(lowercaseSearchTerm)))
      );
    }

    logger.log("Workers found after filtering:", workers.length);
    return workers;
  } catch (error) {
    logger.error("Error in searchWorkers:", error);
    
    // Check for permission-denied error
    if (error.code === 'permission-denied') {
      logger.error("Permission denied. Check Firestore rules.");
      await sendErrorToTelegram('Permission denied in searchWorkers', { searchTerm, city, error: error.message });
      throw new Error('Search failed: Insufficient permissions. Please check Firestore rules.');
    }
    
    await sendErrorToTelegram('Error in searchWorkers', { searchTerm, city, error: error.message });
    throw new Error(`Search failed: ${error.message}`);
  }
};


/**
 * Submits a review for a worker
 * @param {string} userId - The ID of the worker being reviewed
 * @param {string} clientId - The ID of the client submitting the review
 * @param {number} rating - The rating given
 * @param {string} comment - The review comment
 * @returns {Promise<string>} - The ID of the submitted review
 */
export const submitReview = async (userId, clientId, rating, comment, reviewerName) => {
  logger.log('submitReview called with:', { userId, clientId, rating, comment, reviewerName });

  if (!userId || !clientId) {
    logger.error('Invalid userId or clientId:', { userId, clientId });
    throw new Error('Invalid userId or clientId');
  }

  try {
    const reviewId = `${clientId}_${userId}`;
    logger.log('Generated reviewId:', reviewId);

    const reviewRef = doc(db, 'reviews', reviewId);
    logger.log('Review reference created');

    // Check if a review already exists
    const existingReview = await getDoc(reviewRef);
    logger.log('Existing review check completed');

    if (existingReview.exists()) {
      logger.log('Review already exists');
      throw new Error('You have already submitted a review for this worker');
    }

    const reviewData = {
      userId,
      clientId,
      rating,
      comment,
      reviewerName, // Include the reviewer's name in the review document
      createdAt: serverTimestamp()
    };
    logger.log('Review data prepared:', reviewData);

    await setDoc(reviewRef, reviewData);
    logger.log('Review submitted successfully with ID:', reviewId);
    return reviewId;
  } catch (error) {
    logger.error('Error in submitReview:', error);
    await sendErrorToTelegram('Error in submitReview', { error: 'Invalid userId or clientId' });
    throw error;
  }
};

/**
 * Checks if a user has already reviewed a worker
 * @param {string} userId - The ID of the worker
 * @param {string} clientId - The ID of the client
 * @returns {Promise<boolean>} - True if the user has already reviewed, false otherwise
 */
export const hasUserReviewedWorker = async (userId, clientId) => {
  try {
    const reviewQuery = query(
      collection(db, 'reviews'),
      where('userId', '==', userId),
      where('clientId', '==', clientId),
      limit(1)
    );
    const reviewSnapshot = await getDocs(reviewQuery);
    return !reviewSnapshot.empty;
  } catch (error) {
    logger.error('Error checking for existing review:', error);
    await sendErrorToTelegram('Error in hasUserReviewedWorker', { userId, clientId, error: error.message });
    throw error;
  }
};

/**
 * Retrieves all reviews for a worker
 * @param {string} userId - The ID of the worker
 * @returns {Promise<Array>} - An array of review objects
 */
export const getWorkerReviews = async (userId) => {
  try {
    const q = query(collection(db, 'reviews'), where('userId', '==', userId));
    const querySnapshot = await getDocs(q);
    
    const reviews = [];
    querySnapshot.forEach((doc) => {
      reviews.push({ id: doc.id, ...doc.data() });
    });

    logger.log(`Fetched ${reviews.length} reviews for worker ${userId}`);
    return reviews;
  } catch (error) {
    logger.error('Error fetching worker reviews:', error);
    await sendErrorToTelegram('Error in getWorkerReviews', { userId, error: error.message });
    throw error;
  }
};

export const getUserProfile = async (userId) => {
  try {
    const userDocRef = doc(db, 'users', userId);
    const userDocSnap = await getDoc(userDocRef);

    if (userDocSnap.exists()) {
      return userDocSnap.data();
    } else {
      logger.log('No user found with ID:', userId);
      return null;
    }
  } catch (error) {
    logger.error('Error fetching user profile:', error);
    await sendErrorToTelegram('Error in getUserProfile', { userId, error: error.message });
    throw error;
  }
};

export const deleteWorkerProfile = async (userId) => {
  if (!userId) {
    throw new Error('User ID is required');
  }

  try {
    const docRef = doc(db, 'users', userId);
    await deleteDoc(docRef);
    logger.log('Worker profile deleted successfully');
  } catch (error) {
    logger.error('Error deleting worker profile:', error);
    await sendErrorToTelegram('Error in deleteWorkerProfile', { userId, error: error.message });
    throw error;
  }
};

/**
 * Submits user feedback to Firestore
 * @param {string} content - The feedback content
 * @param {string} userId - The user's ID (or 'anonymous' for non-authenticated users)
 * @param {string} userEmail - The user's email (or 'anonymous' for non-authenticated users)
 * @returns {Promise<string>} - The ID of the submitted feedback document
 */
export const submitFeedback = async (content, userId, userEmail) => {
  try {
    const feedbackData = {
      content: content.trim(),
      createdAt: serverTimestamp(),
      userId: userId || 'anonymous',
      userEmail: userEmail || 'anonymous'
    };

    const docRef = await addDoc(collection(db, 'feedback'), feedbackData);
    logger.log('Feedback submitted with ID:', docRef.id);
    return docRef.id;
  } catch (error) {
    logger.error('Error submitting feedback:', error);
    await sendErrorToTelegram('Error in submitFeedback', { userId, userEmail, error: error.message });
    throw error;
  }
};
