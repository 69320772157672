// src/translations.js

export const translations = {
    search: {
      placeholder: "Buscar habilidades o especializaciones",
      cityPlaceholder: "Todas las ciudades",
      button: "Buscar",
      results: "Resultados de búsqueda",
      noResults: "No se encontraron resultados. Intenta ajustar tu búsqueda."
    },
    workerCard: {
      skillNotSpecified: "Habilidad no especificada",
      noSpecializations: "Sin especializaciones",
      cityNotSpecified: "Ciudad no especificada",
      experienceYears: (years) => `${years} años de experiencia`,
      experienceNotSpecified: "Experiencia no especificada",
      hourlyRate: "por hora",
      viewProfile: "Ver perfil completo"
    },
    error: {
      occurred: "Ocurrió un error al buscar",
      tryAgain: "Por favor, inténtalo de nuevo.",
      contactSupport: "Si el problema persiste, por favor contacta a soporte."
    },
    loading: "Cargando..."
  };

  export const homeTranslations = {
    hero: {
      title: "Encuentra proveedores de servicios cerca de ti",
      subtitle: "Conéctate con proveedores de servicios al alcance de tu mano."
    },
    search: {
      inputPlaceholder: "Buscar servicios",
      cityPlaceholder: "Todas las ciudades",
      button: "Buscar",
      errorMessage: "Por favor, ingresa un tipo de servicio o especialidad para buscar."
    },
    howItWorks: {
      title: "Cómo funciona",
      find: {
        title: "Encuentra proveedores de servicio",
        description: "Busca proveedores calificados en tu área según su experiencia y especializaciones."
      },
      connect: {
        title: "Conéctate",
        description: "Revisa perfiles, calificaciones y trabajos anteriores para encontrar la combinación perfecta para tu proyecto."
      },
      collaborate: {
        title: "Colabora",
        description: "Comunícate directamente, acuerda términos y trabajen juntos."
      }
    }
  }; 

  export const workerProfileTranslations = {
    notSpecified: 'No especificado',
    currencyPerHour: 'por hora',
    profileNotFound: 'Perfil no encontrado.',
    goBackHome: 'Volver al inicio',
    editProfile: 'Editar Perfil',
    yearsExperience: 'años de experiencia',
    introduction: 'Introducción',
    specializations: 'Especializaciones',
    noSpecializations: 'No se han listado especializaciones',
    contactInformation: 'Información de Contacto',
    email: 'Correo electrónico',
    phone: 'Teléfono',
    notProvided: 'No proporcionado',
    revealContactInfo: 'Revelar Información de Contacto',
    reviews: 'Reseñas',
    noReviewsYet: 'Aún no hay reseñas.',
    anonymousUser: 'Usuario Anónimo',
    authModalMessage: "Para ver la información de contacto del trabajador, por favor inicia sesión o crea una cuenta si no tienes una.",
    chatWithWorker: 'Chatear con el trabajador', // New translation for chat button
    startChat: 'Iniciar chat', // Alternative text for chat button if needed
    chatModalTitle: 'Chat con', // For the chat modal title, will be followed by the worker's name
    sendMessage: 'Enviar', // For the send button in chat
    typeMessage: 'Escribe un mensaje...', // Placeholder for message input
    loadingChat: 'Cargando chat...', // Loading message for chat
    errors: {
      noProfileId: 'No se proporcionó ID de perfil',
      failedToLoad: 'No se pudo cargar el perfil. Por favor, intenta de nuevo.',
      failedToLoadReviews: 'No se pudieron cargar las reseñas. Por favor, intenta de nuevo.',
      failedToReveal: 'No se pudo revelar la información de contacto. Por favor, intenta de nuevo.',
      failedToSendMessage: 'No se pudo enviar el mensaje. Por favor, intenta de nuevo.', // New error message for chat
      failedToLoadChat: 'No se pudo cargar el chat. Por favor, intenta de nuevo.' // New error message for chat
    },
    chatAuthMessage: "Para chatear con el trabajador, por favor inicia sesión o crea una cuenta si no tienes una." // Message for unauthenticated users trying to chat
  };

 export const workerFormTranslations = {
  firstName: {
    label: "Nombre *",
    error: "El nombre es obligatorio",
    lengthError: (max) => `El nombre debe tener ${max} caracteres o menos`
  },
  lastName: {
    label: "Apellido *",
    error: "El apellido es obligatorio",
    lengthError: (max) => `El apellido debe tener ${max} caracteres o menos`
  },
    city: {
      label: "Ciudad *",
      placeholder: "Selecciona una ciudad",
      error: "La ciudad es obligatoria",
      invalidError: "Por favor, selecciona una ciudad válida"
    },
    introduction: {
      label: "Introducción (Opcional)",
      placeholder: "Preséntate brevemente o describe tu experiencia (máximo 500 caracteres)",
      charactersCount: (current, max) => `${current}/${max} caracteres`
    },
    skill: {
      label: "Habilidad *",
      placeholder: "Selecciona una habilidad",
      error: "La habilidad es obligatoria",
      invalidError: "Por favor, selecciona una habilidad válida"
    },
    specializations: {
      label: "Especializaciones",
      placeholder: "Buscar especializaciones",
      maxError: (max) => `Máximo ${max} etiquetas permitidas`
    },
    experience: {
      label: "Experiencia (años)",
      error: (max) => `La experiencia debe ser un número entre 0 y ${max}`
    },
    hourlyRate: {
      label: "Tarifa por hora (COP)",
      error: (max) => `La tarifa por hora debe ser un número entre 0 y ${max}`
    },
    email: {
      label: "Correo electrónico (Oculto) *",
      error: "El correo electrónico es obligatorio",
      invalidError: "Por favor, ingresa una dirección de correo electrónico válida",
      lengthError: (max) => `El correo electrónico debe tener ${max} caracteres o menos`
    },
    phoneNumber: {
      label: "Teléfono (Oculto)",
      lengthError: (max) => `El número de teléfono debe tener ${max} caracteres o menos`
    },
    photo: {
      label: "Foto de perfil",
      sizeError: "El archivo es demasiado grande. Por favor, elige una imagen de menos de 5MB."
    },
    submit: {
      create: "Crear Perfil",
      update: "Actualizar Perfil",
      processing: "Procesando..."
    },
    success: {
      title: "¡Éxito!",
      created: "Tu perfil ha sido creado. Redirigiendo...",
      updated: "Tu perfil ha sido actualizado. Redirigiendo..."
    },
    error: {
      title: "¡Error!",
      create: "No se pudo crear el perfil. Por favor, inténtalo de nuevo.",
      update: "No se pudo actualizar el perfil. Por favor, inténtalo de nuevo."
    },
    loading: "Cargando...",

    delete: {
      button: 'Borrar perfil',
      confirmTitle: '¿Está seguro de que desea borrar su perfil?',
      confirmMessage: 'Esta acción no se puede deshacer. Todos sus datos de perfil serán eliminados permanentemente.',
      cancel: 'Cancelar',
      confirm: 'Sí, borrar perfil',
      processing: 'Borrando...'
    },

    contactPreference: {
      label: "Preferencia de contacto",
      reveal: "Revelar información de contacto",
      chat: "Permitir chat",
      description: "Elige cómo prefieres que los clientes te contacten"
    },
    isWorker: {
      label: "Mostrar mi perfil como trabajador",
    },
  };

 export const authModalTranslations = {
    titles: {
      signup: 'Crea tu cuenta',
      signin: 'Inicia sesión en tu cuenta'
    },
    placeholders: {
      firstName: 'Nombre',
      lastName: 'Apellido',
      phoneNumber: 'Número de teléfono (10 dígitos)',
      email: 'Correo electrónico',
      password: 'Contraseña'
    },
    buttons: {
      createAccount: 'Crear Cuenta',
      signIn: 'Iniciar Sesión',
      processing: 'Procesando...',
      signUpWithGoogle: 'Registrarse con Google',
      signInWithGoogle: 'Iniciar sesión con Google',
      close: 'Cerrar'
    },
    links: {
      termsOfService: 'Términos de Servicio',
      privacyPolicy: 'Política de Privacidad'
    },
    errors: {
      invalidEmail: 'Por favor, introduce una dirección de correo electrónico válida.',
      weakPassword: 'La contraseña debe tener al menos 8 caracteres.',
      invalidFirstName: 'El nombre debe tener entre 2 y 50 caracteres y contener solo letras, espacios, guiones o apóstrofes.',
      invalidLastName: 'El apellido debe tener entre 2 y 50 caracteres y contener solo letras, espacios, guiones o apóstrofes.',
      invalidPhoneNumber: 'El número de teléfono debe tener exactamente 10 dígitos.'
    },
    messages: {
      agreeToTerms: 'Al hacer clic en Crear Cuenta o Registrarse con Google, aceptas los',
      and: 'y la',
      alreadyHaveAccount: '¿Ya tienes una cuenta?',
      dontHaveAccount: '¿No tienes una cuenta?'
    }
  };

  export const layoutTranslations = {
    header: {
      signIn: 'Iniciar sesión',
      signUp: 'Registrarse'
    },
    profile: {
      viewProfile: 'Ver Perfil',
      logout: 'Cerrar sesión',
      messages: 'Mensajes',
      feedback: "Dar opinión"
    },
    footer: {
      allRightsReserved: 'Todos los derechos reservados',
      termsOfService: 'Términos de Servicio',
      privacyPolicy: 'Política de Privacidad',
      aboutUs: 'Acerca de nosotros',
      feedback: "Dar opinión"
    },
    navigation: {
      home: 'Inicio',
      search: 'Buscar',
      profile: 'Perfil'
    },
    loading: 'Cargando...',
    errors: {
      logoutFailed: 'Error al cerrar sesión'
    }
  };



export const aboutUsTranslations = {
  title: "Sobre Nosotros",
  mission: {
    title: "Nuestra Misión",
    description: "En connekti, nuestra misión es conectar a trabajadores informales con clientes potenciales, creando oportunidades económicas y mejorando la calidad de vida en Colombia.",
  },
  values: {
    title: "Nuestros Valores",
    list: [
      "Empoderamiento de la comunidad",
      "Transparencia y confianza",
      "Innovación y adaptabilidad",
      "Inclusión y accesibilidad",
    ],
  },
  team: {
    title: "Nuestro Equipo",
    members: [
      {
        name: "María Rodríguez",
        role: "Fundadora y CEO",
        bio: "María tiene más de 10 años de experiencia en tecnología y emprendimiento social.",
      },
      {
        name: "Carlos Gómez",
        role: "Director de Tecnología",
        bio: "Carlos es un desarrollador full-stack con pasión por crear soluciones escalables.",
      },
      // Add more team members as needed
    ],
  },
  contact: {
    title: "Contáctanos",
    description: "¿Tienes preguntas o sugerencias? Nos encantaría escucharte.",
    email: "info@connekti.com",
    buttonText: "Envíanos un correo",
  },
};

export const feedbackTranslations = {
  title: "Danos tu opinión",
  description: "Tus comentarios nos ayudan a mejorar. Por favor, comparte tus pensamientos sobre nuestra aplicación.",
  feedbackLabel: "Tu opinión",
  submitButton: "Enviar opinión",
  submitting: "Enviando...",
  successMessage: "¡Gracias por tu opinión! La hemos recibido correctamente.",
  errors: {
    tooShort: "Por favor, proporciona una opinión más detallada (mínimo 10 caracteres).",
    submission: "Hubo un error al enviar tu opinión. Por favor, inténtalo de nuevo."
  },
  navLink: "Dar opinión" // This will be used in the navigation
};

export const messagesPageTranslations = {
  title: "Mensajes",
  searchPlaceholder: "Buscar conversaciones",
  noConversations: "No hay conversaciones aún",
  loadingConversations: "Cargando conversaciones...",
  errorLoadingConversations: "Error al cargar las conversaciones. Por favor, intente nuevamente.",
  tabs: {
    conversations: "Conversaciones",
    chat: "Chat"
  }
};

export const chatModalTranslations = {
  title: "Chat con",
  loadingMessages: "Cargando mensajes...",
  errorLoadingMessages: "Error al cargar los mensajes. Por favor, intente nuevamente.",
  noMessages: "No hay mensajes aún. ¡Comienza la conversación!",
  inputPlaceholder: "Escribe un mensaje...",
  sendButton: "Enviar",
  errorSendingMessage: "Error al enviar el mensaje. Por favor, intente nuevamente."
};