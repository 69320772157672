// src/utils/telegramErrorLogger.js

import axios from 'axios';
import {logger} from './logger';

// Telegram Bot Token
const TELEGRAM_BOT_TOKEN = process.env.REACT_APP_TELEGRAM_BOT_TOKEN;
// Telegram Chat ID
const TELEGRAM_CHAT_ID = process.env.REACT_APP_TELEGRAM_CHAT_ID;

// Log the environment variables (be careful not to log these in production)
logger.log('TELEGRAM_BOT_TOKEN:', TELEGRAM_BOT_TOKEN ? 'Set' : 'Not set');
logger.log('TELEGRAM_CHAT_ID:', TELEGRAM_CHAT_ID ? 'Set' : 'Not set');

/**
 * Sends an error message to a specified Telegram chat.
 * 
 * @param {string} message - The error message to send
 * @param {Object} [extra={}] - Additional information to include in the message
 * @returns {Promise<void>}
 */
export const sendErrorToTelegram = async (message, extra = {}) => {
  if (!TELEGRAM_BOT_TOKEN || !TELEGRAM_CHAT_ID) {
    logger.error('Telegram bot token or chat ID is not set');
    logger.error('TELEGRAM_BOT_TOKEN:', TELEGRAM_BOT_TOKEN ? 'Set' : 'Not set');
    logger.error('TELEGRAM_CHAT_ID:', TELEGRAM_CHAT_ID ? 'Set' : 'Not set');
    return;
  }

  const url = `https://api.telegram.org/bot${TELEGRAM_BOT_TOKEN}/sendMessage`;

  try {
    // Format the message with any extra information
    const formattedMessage = `
ERROR: ${message}
${Object.entries(extra).map(([key, value]) => `${key}: ${value}`).join('\n')}
    `.trim();

    logger.log('Sending message to Telegram:', formattedMessage);

    const response = await axios.post(url, {
      chat_id: TELEGRAM_CHAT_ID,
      text: formattedMessage,
    });

    logger.log('Error message sent to Telegram. Response:', response.data);
  } catch (error) {
    logger.error('Failed to send error message to Telegram:', error);
    if (error.response) {
      logger.error('Error response:', error.response.data);
    }
  }
};

// Test function to check if environment variables are accessible
export const testEnvironmentVariables = () => {
  logger.log('Testing environment variables:');
  logger.log('REACT_APP_TELEGRAM_BOT_TOKEN:', process.env.REACT_APP_TELEGRAM_BOT_TOKEN ? 'Set' : 'Not set');
  logger.log('REACT_APP_TELEGRAM_CHAT_ID:', process.env.REACT_APP_TELEGRAM_CHAT_ID ? 'Set' : 'Not set');
};