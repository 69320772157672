import React from 'react';
import { useNavigate } from 'react-router-dom';
import { UserPlus, Search, Briefcase, Users } from 'lucide-react';

const ProfilePrompt = () => {
  const navigate = useNavigate();

  const handleCreateProfile = () => navigate('/profile/create');
  const handleContinueAsCustomer = () => navigate('/search');

  return (
    <div className="max-w-md mx-auto mt-4 p-4 sm:p-6 bg-white rounded-lg shadow-lg">
      <h2 className="text-2xl font-bold mb-4 text-center flex items-center justify-center text-blue-600">
        <Users className="mr-2" size={28} />
        Welcome to Worker Connect!
      </h2>
      
      <p className="mb-6 text-gray-600 text-center">
        Choose your path to get started:
      </p>
      
      <div className="grid grid-cols-1 gap-6">
        {/* Worker Option */}
        <button
          onClick={handleCreateProfile}
          className="flex flex-col items-center p-6 bg-blue-100 rounded-lg hover:bg-blue-200 transition duration-300 group"
        >
          <div className="mb-4 p-4 bg-blue-500 text-white rounded-full group-hover:scale-110 transition duration-300">
            <UserPlus size={48} />
          </div>
          <h3 className="text-xl font-semibold mb-2 text-blue-700">Become a Worker</h3>
          <p className="text-center text-blue-600">
            Create a profile and offer your skills to potential clients.
          </p>
        </button>

        {/* Customer Option */}
        <button
          onClick={handleContinueAsCustomer}
          className="flex flex-col items-center p-6 bg-green-100 rounded-lg hover:bg-green-200 transition duration-300 group"
        >
          <div className="mb-4 p-4 bg-green-500 text-white rounded-full group-hover:scale-110 transition duration-300">
            <Briefcase size={48} />
          </div>
          <h3 className="text-xl font-semibold mb-2 text-green-700">Find Workers</h3>
          <p className="text-center text-green-600">
            Browse and connect with skilled professionals for your projects.
          </p>
        </button>
      </div>
    </div>
  );
};

export default ProfilePrompt;