import React, { useState, useRef, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { Home, Search, User, ChevronDown, MessageSquare, ThumbsUp, LogOut } from 'lucide-react';
import AuthModal from './AuthModal';
import { layoutTranslations } from '../translations';
import {logger} from '../utils/logger';

const Layout = ({ children }) => {
  const { currentUser, logout, loading } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const [showAuthModal, setShowAuthModal] = useState(false);
  const [authMode, setAuthMode] = useState('signin');
  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownRef = useRef(null);

  const isActive = (path) => location.pathname === path;

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/');
      logger.log('Logged out successfully');
    } catch (error) {
      logger.error('Failed to log out', error);
      // TODO: Implement user-friendly error notification
    }
  };

  const handleProfileClick = () => {
    if (currentUser) {
      setShowDropdown(!showDropdown);
    } else {
      logger.log('User not logged in. Showing auth modal.');
      setAuthMode('signin');
      setShowAuthModal(true);
    }
  };

  const handleAuthClick = (mode) => {
    setAuthMode(mode);
    setShowAuthModal(true);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    setShowDropdown(false);
  }, [currentUser]);

  if (loading) {
    return <div className="flex justify-center items-center h-screen">Loading...</div>;
  }

  return (
    <div className="flex flex-col min-h-screen">
      <header className="bg-blue-600 text-white p-4 shadow-md">
        <div className="container mx-auto flex justify-between items-center">
          <Link to="/" className="text-2xl font-bold hover:text-blue-200 transition duration-300">
            Conekti
          </Link>
          <div className="flex items-center space-x-4">
            {currentUser ? (
              <div className="relative" ref={dropdownRef}>
                <button 
                  onClick={handleProfileClick}
                  className="flex items-center text-white hover:text-blue-200 transition duration-300"
                >
                  <User size={24} />
                  <ChevronDown size={18} className="ml-1" />
                </button>
                {showDropdown && (
                  <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg py-1 z-10">
                    <Link 
                      to={`/profile/${currentUser.uid}`} 
                      className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                      onClick={() => setShowDropdown(false)}
                    >
                      <div className="flex items-center">
                        <User size={18} className="mr-2" />
                        {layoutTranslations.profile.viewProfile}
                      </div>
                    </Link>
                    <Link 
                      to="/messages" 
                      className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                      onClick={() => setShowDropdown(false)}
                    >
                      <div className="flex items-center">
                        <MessageSquare size={18} className="mr-2" />
                        {layoutTranslations.profile.messages}
                      </div>
                    </Link>
                    <Link 
                      to="/feedback" 
                      className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                      onClick={() => setShowDropdown(false)}
                    >
                      <div className="flex items-center">
                        <ThumbsUp size={18} className="mr-2" />
                        {layoutTranslations.profile.feedback}
                      </div>
                    </Link>
                    <button 
                      onClick={handleLogout}
                      className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                    >
                      <div className="flex items-center">
                        <LogOut size={18} className="mr-2" />
                        {layoutTranslations.profile.logout}
                      </div>
                    </button>
                  </div>
                )}
              </div>
            ) : (
              <>
                <button onClick={() => handleAuthClick('signin')} className="text-white hover:text-blue-200 transition duration-300">
                  {layoutTranslations.header.signIn}
                </button>
                <button onClick={() => handleAuthClick('signup')} className="text-white hover:text-blue-200 transition duration-300">
                  {layoutTranslations.header.signUp}
                </button>
              </>
            )}
          </div>
        </div>
      </header>

      <main className="flex-grow container mx-auto px-4 py-8">
        {children}
      </main>

      <footer className="bg-gray-200 text-center p-6">
        <div className="container mx-auto">
          <p className="text-sm text-gray-600 mb-4">&copy; {new Date().getFullYear()} conekti. {layoutTranslations.footer.allRightsReserved}</p>
          <div className="flex flex-wrap justify-center gap-4">
            <Link to="/about-us" className="text-blue-600 hover:text-blue-800 transition duration-300">
              {layoutTranslations.footer.aboutUs}
            </Link>
            <Link to="/terms-of-service" className="text-blue-600 hover:text-blue-800 transition duration-300">
              {layoutTranslations.footer.termsOfService}
            </Link>
            <Link to="/privacy-policy" className="text-blue-600 hover:text-blue-800 transition duration-300">
              {layoutTranslations.footer.privacyPolicy}
            </Link>
            <Link to="/feedback" className="text-blue-600 hover:text-blue-800 transition duration-300">
              {layoutTranslations.footer.feedback}
            </Link>
          </div>
        </div>
      </footer>

      <nav className="bg-white shadow-lg">
        <ul className="flex justify-around items-center h-16">
          <li>
            <Link to="/" className={`flex flex-col items-center ${isActive('/') ? 'text-blue-600' : 'text-gray-600'}`}>
              <Home size={24} />
              <span className="text-xs mt-1">{layoutTranslations.navigation.home}</span>
            </Link>
          </li>
          <li>
            <Link to="/search" className={`flex flex-col items-center ${isActive('/search') ? 'text-blue-600' : 'text-gray-600'}`}>
              <Search size={24} />
              <span className="text-xs mt-1">{layoutTranslations.navigation.search}</span>
            </Link>
          </li>
          <li>
            <button
              onClick={handleProfileClick}
              className={`flex flex-col items-center ${isActive(`/profile/${currentUser?.uid}`) ? 'text-blue-600' : 'text-gray-600'}`}
            >
              <User size={24} />
              <span className="text-xs mt-1">{layoutTranslations.navigation.profile}</span>
            </button>
          </li>
        </ul>
      </nav>

      {showAuthModal && (
        <AuthModal
          mode={authMode}
          onClose={() => setShowAuthModal(false)}
          onSwitchMode={(newMode) => setAuthMode(newMode)}
        />
      )}
    </div>
  );
};

export default Layout;