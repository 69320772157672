import React, { useState, useEffect } from 'react';
import { X, Loader } from 'lucide-react';
import { ref, push, onValue, off, update, get, set } from 'firebase/database';
import { realtimeDb } from '../firebase';
import { useAuth } from '../contexts/AuthContext';
import { chatModalTranslations, workerProfileTranslations } from '../translations';
import {logger} from '../utils/logger';


const ChatModal = ({ workerId, workerName, onClose }) => {
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const { currentUser } = useAuth();
  const workerFirstName = workerName ? workerName.split(' ')[0] : 'Worker';

  const chatId = [currentUser.uid, workerId].sort().join('_');

  useEffect(() => {
    const setupChat = async () => {
      const chatRef = ref(realtimeDb, `chats/${chatId}`);
      const userChatsRefCurrent = ref(realtimeDb, `userChats/${currentUser.uid}/${chatId}`);
      const userChatsRefWorker = ref(realtimeDb, `userChats/${workerId}/${chatId}`);
      

      try {
        // Check if chat exists
        const chatSnapshot = await get(chatRef);
        
        if (!chatSnapshot.exists()) {
          // Create new chat
          await set(chatRef, {
            participants: {
              [currentUser.uid]: true,
              [workerId]: true
            },
            messages: {}
          });

          // Update userChats for both users
          await set(userChatsRefCurrent, true);
          await set(userChatsRefWorker, true);
        }

        // Set up listener for messages
        const messagesRef = ref(realtimeDb, `chats/${chatId}/messages`);
        const unsubscribe = onValue(messagesRef, (snapshot) => {
          setIsLoading(false);
          const data = snapshot.val();
          if (data) {
            const messageList = Object.entries(data).map(([key, value]) => ({
              id: key,
              ...value
            })).sort((a, b) => a.timestamp - b.timestamp);
            setMessages(messageList);
          } else {
            setMessages([]);
          }
        }, (err) => {
          logger.error("Error fetching messages:", err);
          setError(workerProfileTranslations.errorFetchingMessages);
          setIsLoading(false);
        });

        return () => off(messagesRef);
      } catch (err) {
        logger.error("Error setting up chat:", err);
        setError(workerProfileTranslations.errorSettingUpChat);
        setIsLoading(false);
      }
    };

    setupChat();
  }, [chatId, currentUser.uid, workerId]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!newMessage.trim()) return;

    setError(null);

    const messagesRef = ref(realtimeDb, `chats/${chatId}/messages`);
    const newMessageRef = push(messagesRef);
    
    const messageData = {
      text: newMessage,
      senderId: currentUser.uid,
      senderName: currentUser.displayName || 'Anonymous',
      timestamp: Date.now()
    };

    set(newMessageRef, messageData)
      .then(() => {
        logger.log('Message sent successfully');
        setNewMessage('');
      })
      .catch((error) => {
        logger.error('Error sending message:', error);
        setError(workerProfileTranslations.errorSendingMessage);
      });
  };

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full" id="chat-modal">
      <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-lg font-medium">{chatModalTranslations.title} {workerFirstName}</h3>
          <button onClick={onClose} className="text-gray-400 hover:text-gray-500">
            <X size={24} />
          </button>
        </div>
        <div className="h-64 overflow-y-auto mb-4 p-2 border rounded">
          {isLoading ? (
            <div className="flex justify-center items-center h-full">
              <Loader className="animate-spin text-blue-500" size={24} />
              <span className="ml-2">{chatModalTranslations.loadingMessages}</span>
            </div>
          ) : error ? (
            <div className="text-red-500 text-center">{error}</div>
          ) : messages.length === 0 ? (
            <div className="text-gray-500 text-center">{chatModalTranslations.noMessages}</div>
          ) : (
            messages.map((message) => (
              <div key={message.id} className={`mb-2 ${message.senderId === currentUser.uid ? 'text-right' : 'text-left'}`}>
                <span className="inline-block bg-blue-100 rounded px-2 py-1">
                  <strong>{message.senderName}: </strong>{message.text}
                </span>
                <div className="text-xs text-gray-500 mt-1">
                  {new Date(message.timestamp).toLocaleString()}
                </div>
              </div>
            ))
          )}
        </div>
        <form onSubmit={handleSubmit} className="flex">
          <input
            type="text"
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
            placeholder={chatModalTranslations.inputPlaceholder}
            className="flex-grow mr-2 p-2 border rounded"
          />
          <button type="submit" className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600">
            {chatModalTranslations.sendButton}
          </button>
        </form>
      </div>
    </div>
  );
};

export default ChatModal;
