import React, { useEffect, useState, useCallback } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import WorkerProfileForm from '../components/WorkerProfileForm';
import WorkerProfileDisplay from '../components/WorkerProfileDisplay';
import ProfilePrompt from '../components/ProfilePrompt';
import { useAuth } from '../contexts/AuthContext';
import { getUserProfileByUserId, getUserProfileWithHiddenInfo } from '../utils/firestoreOperations';
import { Loader } from 'lucide-react';
import { sendErrorToTelegram } from '../utils/telegramErrorLogger';
import AuthModal from '../components/AuthModal';
import {logger} from '../utils/logger';

const ProfilePage = ({ isCreating = false, isEditing = false }) => {
  // State declarations
  const [profile, setProfile] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');
  const [showPrompt, setShowPrompt] = useState(false);
  const [showAuthModal, setShowAuthModal] = useState(false);

  // Hooks
  const { currentUser } = useAuth();
  const { userId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  // Derived state
  const isOwnProfile = currentUser && currentUser.uid === userId;
  const isEditMode = isEditing || location.pathname.endsWith('/edit');

  // Fetch profile data
  const fetchProfile = useCallback(async () => {
    if (isCreating) {
      setIsLoading(false);
      return;
    }

    try {
      setIsLoading(true);
      setError('');
      let fetchedProfile;
      const profileUserId = userId || currentUser?.uid;

      if (currentUser) {
        fetchedProfile = await getUserProfileByUserId(profileUserId);
      } else {
        fetchedProfile = await getUserProfileWithHiddenInfo(profileUserId);
      }

      if (fetchedProfile) {
        setProfile(fetchedProfile);
      } else if (isOwnProfile) {
        setShowPrompt(true);
      } else {
        setError('Profile not found.');
      }
    } catch (err) {
      logger.error('Error fetching profile:', err);
      setError('Failed to load profile. Please try again.');
      sendErrorToTelegram('Error fetching profile in ProfilePage', { error: err.message });
    } finally {
      setIsLoading(false);
    }
  }, [currentUser, userId, isCreating, isOwnProfile]);

  // Effect to fetch profile data
  useEffect(() => {
    fetchProfile();
  }, [fetchProfile]);

  // Handle successful authentication
  const handleAuthSuccess = () => {
    setShowAuthModal(false);
    fetchProfile();
  };

  // Check for authentication and authorization
  if (!currentUser && (isCreating || isEditMode)) {
    logger.log('User not authenticated, showing auth modal');
    return (
      <AuthModal
        mode="signin"
        onClose={() => navigate('/')}
        onAuthSuccess={handleAuthSuccess}
        customMessage="Please sign in to view or edit your profile."
      />
    );
  }

  if (isEditMode && !isOwnProfile) {
    logger.log('Redirecting to profile view: Not authorized to edit');
    navigate(`/profile/${userId}`);
    return null;
  }

  // Render loading state
  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <Loader className="animate-spin text-blue-500" size={48} />
        <span className="ml-2">Loading...</span>
      </div>
    );
  }

  // Render error state
  if (error) {
    return (
      <div className="text-red-500 text-center p-4">
        <p>{error}</p>
        <button 
          onClick={() => navigate('/')} 
          className="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
        >
          Back to Home
        </button>
      </div>
    );
  }

  // Render profile prompt if needed
  if (showPrompt && !isCreating) {
    return <ProfilePrompt />;
  }

  // Render create/edit form
  if (isCreating || isEditMode) {
    return (
      <div className="container mx-auto p-4">
        <h1 className="text-2xl font-bold mb-4">
          {isCreating ? 'Create Your Profile' : 'Edit Your Profile'}
        </h1>
        <WorkerProfileForm 
          initialProfile={isCreating ? null : profile} 
          isCreating={isCreating}
        />
      </div>
    );
  }

  // Render profile not found
  if (!profile) {
    return <div className="text-center py-8">Profile not found.</div>;
  }

  // Render profile display
  return (
    <div className="container mx-auto p-4">
      <WorkerProfileDisplay 
        profile={profile}
        isOwnProfile={isOwnProfile}
        onEditClick={() => navigate(`/profile/${userId}/edit`)}
      />
    </div>
  );
};

export default ProfilePage;