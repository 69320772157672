import { db } from '../firebase';
import { doc, getDoc, getDocs, query, where, collection } from 'firebase/firestore';
import { sendErrorToTelegram } from '../utils/telegramErrorLogger';
import { logger } from '../utils/logger';

class UserDataService {
  constructor() {
    this.userCache = new Map();
  }

  async getUserFirstName(userId) {
    logger.log(`Fetching first name for user: ${userId}`);
    if (this.userCache.has(userId)) {
      logger.log(`Returning cached data for user: ${userId}`);
      return this.userCache.get(userId).firstName;
    }

    try {
      const userDoc = await getDoc(doc(db, 'users', userId));
      if (userDoc.exists()) {
        const userData = userDoc.data();
        this.userCache.set(userId, userData);
        logger.log(`User data fetched and cached for: ${userId}`);
        return userData.firstName;
      }
      logger.warn(`No user found with ID: ${userId}`);
      return 'Unknown';
    } catch (error) {
      logger.error('Error fetching user data:', error);
      await sendErrorToTelegram('Error in getUserFirstName', {
        userId,
        errorMessage: error.message,
        fileName: 'UserDataService.js'
      });
      return 'Unknown';
    }
  }

  async batchGetUserFirstNames(userIds) {
    logger.log(`Batch fetching first names for users: ${userIds.join(', ')}`);
    const uncachedIds = userIds.filter(id => !this.userCache.has(id));
    
    if (uncachedIds.length > 0) {
      try {
        logger.log(`Fetching data for uncached users: ${uncachedIds.join(', ')}`);
        // Use 'in' operator with document IDs directly
        const q = query(collection(db, 'users'), where('__name__', 'in', uncachedIds));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach(doc => {
          const userData = doc.data();
          // Use document ID as the key for caching
          this.userCache.set(doc.id, { ...userData, userId: doc.id });
          logger.log(`Cached data for user: ${doc.id}`);
        });
      } catch (error) {
        logger.error('Error batch fetching user data:', error);
        await sendErrorToTelegram('Error in batchGetUserFirstNames', {
          userIds: uncachedIds.join(', '),
          errorMessage: error.message,
          fileName: 'UserDataService.js'
        });
      }
    }

    return userIds.map(id => {
      const firstName = this.userCache.get(id)?.firstName || 'Unknown';
      logger.log(`Returning first name for user ${id}: ${firstName}`);
      return { userId: id, firstName };
    });
  }
}

export const userDataService = new UserDataService();