import React from 'react';
import ReactMarkdown from 'react-markdown';

const termsOfServiceContent = `


Ultima actualización: [Current Date]

# Términos y Condiciones para la Plataforma Service Connect

Última actualización: [Fecha actual]

Por favor, lea estos Términos y Condiciones ("Términos", "Términos y Condiciones") cuidadosamente antes de usar la plataforma Service Connect (el "Servicio") operada por [Nombre de su empresa] ("nosotros", "nos" o "nuestro").

Su acceso y uso del Servicio está condicionado a su aceptación y cumplimiento de estos Términos. Estos Términos se aplican a todos los visitantes, usuarios y otros que accedan o utilicen el Servicio.

Al acceder o utilizar el Servicio, usted acepta estar sujeto a estos Términos. Si no está de acuerdo con alguna parte de los términos, entonces no podrá acceder al Servicio.

## 1. Definiciones

- "Plataforma" se refiere al sitio web de Service Connect, la aplicación móvil y cualquier servicio relacionado.
- "Usuario" se refiere a cualquier individuo o entidad que acceda o utilice la Plataforma, incluyendo Proveedores de Servicios y Clientes.
- "Proveedor de Servicios" se refiere a cualquier Usuario que ofrezca servicios a través de la Plataforma.
- "Cliente" se refiere a cualquier Usuario que busque servicios a través de la Plataforma.
- "Servicio" se refiere a cualquier tarea, trabajo o servicio ofrecido por un Proveedor de Servicios a través de la Plataforma.

## 2. Registro de Cuenta y Seguridad

2.1. Para utilizar ciertas funciones de la Plataforma, debe registrar una cuenta. Usted acepta proporcionar información precisa, actualizada y completa durante el proceso de registro y actualizar dicha información para mantenerla precisa, actualizada y completa.

2.2. Usted es responsable de salvaguardar la contraseña que utiliza para acceder al Servicio y de cualquier actividad o acción bajo su contraseña.

2.3. Usted acepta no revelar su contraseña a ningún tercero. Debe notificarnos inmediatamente al tener conocimiento de cualquier violación de seguridad o uso no autorizado de su cuenta.

2.4. Nos reservamos el derecho de rechazar el servicio, terminar cuentas, eliminar o editar contenido, o cancelar listados a nuestra entera discreción.

## 3. Conducta del Usuario

3.1. Usted acepta utilizar la Plataforma solo para fines lícitos y de acuerdo con estos Términos. Usted acepta no utilizar la Plataforma:

   a) De ninguna manera que viole cualquier ley o regulación nacional, federal, estatal, local o internacional aplicable.
   
   b) Para transmitir, o procurar el envío de, cualquier material publicitario o promocional, incluyendo cualquier "correo basura", "cadena de cartas", "spam" o cualquier otra solicitud similar.
   
   c) Para suplantar o intentar suplantar a la Compañía, un empleado de la Compañía, otro usuario o cualquier otra persona o entidad.
   
   d) Para participar en cualquier otra conducta que restrinja o inhiba el uso o disfrute de la Plataforma por parte de cualquier persona, o que, según lo determinemos, pueda dañar a la Compañía o a los usuarios de la Plataforma o exponerlos a responsabilidad.

3.2. Además, usted acepta no:

   a) Utilizar la Plataforma de cualquier manera que pudiera deshabilitar, sobrecargar, dañar o perjudicar el sitio o interferir con el uso de la Plataforma por parte de cualquier otra parte.
   
   b) Utilizar cualquier robot, araña u otro dispositivo, proceso o medio automático para acceder a la Plataforma para cualquier propósito, incluyendo monitorear o copiar cualquier material en la Plataforma.
   
   c) Utilizar cualquier proceso manual para monitorear o copiar cualquier material en la Plataforma o para cualquier otro propósito no autorizado sin nuestro consentimiento previo por escrito.
   
   d) Utilizar cualquier dispositivo, software o rutina que interfiera con el funcionamiento adecuado de la Plataforma.
   
   e) Introducir virus, troyanos, gusanos, bombas lógicas u otro material que sea malicioso o tecnológicamente dañino.
   
   f) Intentar obtener acceso no autorizado, interferir, dañar o interrumpir cualquier parte de la Plataforma, el servidor en el que se almacena la Plataforma, o cualquier servidor, computadora o base de datos conectada a la Plataforma.
   
   g) Atacar la Plataforma mediante un ataque de denegación de servicio o un ataque distribuido de denegación de servicio.
   
   h) De otra manera intentar interferir con el funcionamiento adecuado de la Plataforma.

## 4. Responsabilidades del Proveedor de Servicios

4.1. Los Proveedores de Servicios declaran y garantizan que tienen el derecho, el poder y la capacidad para celebrar este acuerdo y para realizar los Servicios ofrecidos.

4.2. Los Proveedores de Servicios son responsables de proporcionar todo el equipo, herramientas y materiales necesarios para completar los Servicios, a menos que se acuerde lo contrario con el Cliente.

4.3. Los Proveedores de Servicios deben realizar los Servicios de manera profesional y competente, de acuerdo con los estándares de la industria y cualquier requisito específico acordado con el Cliente.

4.4. Los Proveedores de Servicios deben cumplir con todas las leyes, regulaciones y estándares profesionales aplicables relacionados con los Servicios que proporcionan.

4.5. Los Proveedores de Servicios son responsables de mantener información de perfil precisa y actualizada, incluyendo habilidades, experiencia y disponibilidad.

4.6. Los Proveedores de Servicios deben comunicarse de manera pronta y profesional con los Clientes con respecto a las solicitudes de Servicio, el progreso y cualquier problema que pueda surgir.

4.7. Los Proveedores de Servicios deben respetar la privacidad y confidencialidad del Cliente, y no deben divulgar ninguna información del Cliente obtenida a través de la Plataforma o durante la prestación de los Servicios.

## 5. Responsabilidades del Cliente

5.1. Los Clientes son responsables de proporcionar descripciones claras y precisas de los Servicios que requieren.

5.2. Los Clientes deben comunicarse de manera pronta y profesional con los Proveedores de Servicios con respecto a los requisitos del Servicio, retroalimentación y cualquier problema que pueda surgir.

5.3. Los Clientes son responsables de proporcionar cualquier acceso, información o materiales necesarios para que el Proveedor de Servicios realice los Servicios acordados.

5.4. Los Clientes deben respetar la privacidad del Proveedor de Servicios y no deben compartir información personal sobre los Proveedores de Servicios obtenida a través de la Plataforma.

## 6. Acuerdos de Servicio

6.1. Cualquier acuerdo para Servicios es únicamente entre el Proveedor de Servicios y el Cliente. Nosotros no somos parte de ningún acuerdo de Servicio y no somos responsables de ningún aspecto de la prestación del Servicio.

6.2. No garantizamos la calidad, seguridad o legalidad de los Servicios ofrecidos, la veracidad o precisión de los listados de los Proveedores de Servicios, la capacidad de los Proveedores de Servicios para proporcionar Servicios, o la capacidad de los Clientes para pagar por los Servicios.

6.3. Los Usuarios son los únicos responsables de determinar la idoneidad de otros Usuarios para un acuerdo de Servicio.

6.4. Los Usuarios reconocen que la Plataforma no maneja ningún pago o transacción financiera entre Proveedores de Servicios y Clientes. Cualquier arreglo financiero debe hacerse directamente entre el Proveedor de Servicios y el Cliente fuera de la Plataforma.

## 7. Calificaciones y Reseñas

7.1. Los Usuarios pueden dejar calificaciones y reseñas para los Servicios completados a través de la Plataforma.

7.2. Las calificaciones y reseñas deben ser honestas, precisas y relacionarse específicamente con el Servicio proporcionado.

7.3. Nos reservamos el derecho de eliminar o editar calificaciones y reseñas que determinemos, a nuestra entera discreción, que son fraudulentas, abusivas o inapropiadas de cualquier otra manera.

## 8. Propiedad Intelectual

8.1. La Plataforma y su contenido original, características y funcionalidad son y seguirán siendo propiedad exclusiva de [Nombre de su empresa] y sus licenciantes.

8.2. Los Usuarios conservan la propiedad de cualquier derecho de propiedad intelectual sobre el contenido que envían a la Plataforma. Al enviar contenido, los Usuarios nos otorgan una licencia mundial, no exclusiva, libre de regalías para usar, reproducir, modificar, adaptar, publicar, traducir y distribuirlo en cualquier medio.

## 9. Privacidad

9.1. Nuestra Política de Privacidad, disponible en nuestro sitio web, explica cómo recopilamos, usamos y compartimos información sobre usted cuando utiliza la Plataforma. Al usar la Plataforma, usted acepta la recopilación, uso y compartición de su información como se describe en la Política de Privacidad.

## 10. Limitación de Responsabilidad

10.1. En la medida máxima permitida por la ley, [Nombre de su empresa] no será responsable por ningún daño indirecto, incidental, especial, consecuente o punitivo, o cualquier pérdida de beneficios o ingresos, ya sea incurrida directa o indirectamente, o cualquier pérdida de datos, uso, buena voluntad u otras pérdidas intangibles, resultantes de:

   a) Su acceso o uso o incapacidad para acceder o usar la Plataforma;
   
   b) Cualquier conducta o contenido de terceros en la Plataforma, incluyendo, sin limitación, cualquier conducta difamatoria, ofensiva o ilegal de otros usuarios o terceros;
   
   c) Cualquier contenido obtenido de la Plataforma; o
   
   d) Acceso, uso o alteración no autorizados de sus transmisiones o contenido.

10.2. En ningún caso nuestra responsabilidad total hacia usted por todas las reclamaciones relacionadas con el Servicio excederá la cantidad pagada por usted, si la hubiera, a [Nombre de su empresa] por el acceso y uso de la Plataforma durante los doce (12) meses inmediatamente anteriores a la fecha de la reclamación.

## 11. Descargo de Garantías

11.1. La Plataforma se proporciona "TAL CUAL" y "SEGÚN DISPONIBILIDAD". [Nombre de su empresa] renuncia expresamente a todas las garantías de cualquier tipo, ya sean expresas o implícitas, incluyendo, pero no limitado a, las garantías implícitas de comerciabilidad, idoneidad para un propósito particular y no infracción.

11.2. [Nombre de su empresa] no garantiza que:

   a) La Plataforma cumplirá con sus requisitos;
   
   b) La Plataforma será ininterrumpida, oportuna, segura o libre de errores;
   
   c) Los resultados que se puedan obtener del uso de la Plataforma serán precisos o confiables;
   
   d) La calidad de cualquier Servicio, información u otro material obtenido por usted a través de la Plataforma cumplirá con sus expectativas.

## 12. Indemnización

12.1. Usted acepta defender, indemnizar y mantener indemne a [Nombre de su empresa], sus afiliados, licenciantes y proveedores de servicios, y sus respectivos funcionarios, directores, empleados, contratistas, agentes, licenciantes, proveedores, sucesores y cesionarios de y contra cualquier reclamación, responsabilidad, daño, juicio, premio, pérdida, costo, gasto o tarifa (incluyendo honorarios razonables de abogados) que surjan de o estén relacionados con:

   a) Su violación de estos Términos;
   
   b) Su uso de la Plataforma, incluyendo, pero no limitado a, sus interacciones con otros usuarios, su uso de los Servicios y cualquier contenido que envíe a la Plataforma;
   
   c) Cualquier violación real o presunta de las leyes o regulaciones aplicables por parte de usted en relación con su uso de la Plataforma o los Servicios.

## 13. Ley Aplicable y Jurisdicción

13.1. Estos Términos se regirán e interpretarán de acuerdo con las leyes de [Su Jurisdicción], sin tener en cuenta sus disposiciones sobre conflictos de leyes.

13.2. Cualquier disputa que surja de o esté relacionada con el objeto de estos Términos será finalmente resuelta mediante arbitraje en [Su Ciudad, Estado/Provincia], utilizando el idioma inglés de acuerdo con las Reglas y Procedimientos de Arbitraje de [Servicio de Arbitraje Preferido] entonces vigentes, por un árbitro comercial con experiencia sustancial en la resolución de disputas de propiedad intelectual y contratos comerciales.

## 14. Cambios en los Términos

14.1. Nos reservamos el derecho, a nuestra entera discreción, de modificar o reemplazar estos Términos en cualquier momento. Proporcionaremos aviso de cualquier cambio significativo publicando los nuevos Términos en la Plataforma y actualizando la fecha de "Última actualización" en la parte superior de estos Términos.

14.2. Su uso continuado de la Plataforma después de dichos cambios constituye su aceptación de los nuevos Términos.

## 15. Terminación

15.1. Podemos terminar o suspender su cuenta inmediatamente, sin previo aviso o responsabilidad, por cualquier razón, incluyendo, sin limitación, si usted incumple los Términos.

15.2. Tras la terminación, su derecho a utilizar la Plataforma cesará inmediatamente.

## 16. Divisibilidad

16.1. Si alguna disposición de estos Términos se considera inaplicable o inválida, dicha disposición será cambiada e interpretada para lograr los objetivos de dicha disposición en la mayor medida posible bajo la ley aplicable, y las disposiciones restantes continuarán en pleno vigor y efecto.

## 17. Acuerdo Completo

17.1. Estos Términos, junto con la Política de Privacidad y cualquier otro aviso legal publicado por [Nombre de su empresa] en la Plataforma, constituirán el ac

`;

const TermsOfService = () => {
  console.log("Rendering TermsOfService component");
  console.log("Content:", termsOfServiceContent);

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="prose prose-sm sm:prose lg:prose-lg xl:prose-xl max-w-none">
        <ReactMarkdown
          components={{
            h1: ({node, ...props}) => <h1 style={{color: '#2c3e50', fontSize: '2.5em', marginBottom: '0.5em'}} {...props} />,
            h2: ({node, ...props}) => <h2 style={{color: '#34495e', fontSize: '2em', marginBottom: '0.5em'}} {...props} />,
            h3: ({node, ...props}) => <h3 style={{color: '#7f8c8d', fontSize: '1.5em', marginBottom: '0.5em'}} {...props} />,
            p: ({node, ...props}) => <p style={{marginBottom: '1em', lineHeight: '1.6'}} {...props} />,
            ul: ({node, ...props}) => <ul style={{marginBottom: '1em', paddingLeft: '2em'}} {...props} />,
            ol: ({node, ...props}) => <ol style={{marginBottom: '1em', paddingLeft: '2em'}} {...props} />,
            li: ({node, ...props}) => <li style={{marginBottom: '0.5em'}} {...props} />,
          }}
        >
          {termsOfServiceContent}
        </ReactMarkdown>
      </div>
    </div>
  );
};

export default TermsOfService;