// src/utils/analytics.js
import { logEvent, setUserId, setUserProperties } from 'firebase/analytics';
import { analytics } from '../firebase';

// List of valid event names (example, expand as needed)
const VALID_EVENTS = [
  'signup',
  'login',
  'search_performed',
  'search_result_profile_view',
  'contact_info_revealed',
  'chat_initiated',
  // ... add more valid events
];

export const logAnalyticsEvent = (eventName, eventParams = {}) => {
  try {
    // Validate event name
    if (!VALID_EVENTS.includes(eventName)) {
      console.warn(`Invalid event name: ${eventName}. Event not logged.`);
      return;
    }

    // Sanitize event parameters
    const sanitizedParams = Object.entries(eventParams).reduce((acc, [key, value]) => {
      // Remove any null, undefined, or empty string values
      if (value != null && value !== '') {
        acc[key] = value;
      }
      return acc;
    }, {});

    // Add environment parameter
    const environment = process.env.NODE_ENV === 'production' ? 'production' : 'development';
    sanitizedParams.environment = environment;

    // Log to Firebase Analytics
    logEvent(analytics, eventName, sanitizedParams);

    // Debug logging
    if (environment === 'development' || process.env.REACT_APP_ENABLE_ANALYTICS_DEBUG === 'true') {
      console.log(`Analytics event logged (${environment}):`, eventName, sanitizedParams);
    }
  } catch (error) {
    console.error(`Failed to log analytics event: ${eventName}`, error);
    // You might want to send this error to your error logging service
  }
};

export const setAnalyticsUserId = (userId) => {
  try {
    setUserId(analytics, userId);
    console.log(`Analytics user ID set: ${userId}`);
  } catch (error) {
    console.error('Failed to set analytics user ID:', error);
  }
};

export const setAnalyticsUserProperties = (properties) => {
  try {
    setUserProperties(analytics, properties);
    console.log('Analytics user properties set:', properties);
  } catch (error) {
    console.error('Failed to set analytics user properties:', error);
  }
};

export const initializeAnalytics = (userId = null, userProperties = {}) => {
  if (userId) {
    setAnalyticsUserId(userId);
  }
  if (Object.keys(userProperties).length > 0) {
    setAnalyticsUserProperties(userProperties);
  }
};