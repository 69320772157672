// src/data/skills.js

const skills = [
  "Limpieza de casas",
  "Carpintería",
  "Plomería",
  "Electricidad",
  "Albañilería",
  "Jardinería",
  "Pintura",
  "Conducción de taxis",
  "Peluquería",
  "Manicura y pedicura",
  "Costura y sastrería",
  "Reparación de electrodomésticos",
  "Mecánica automotriz",
  "Instalación de aires acondicionados",
  "Cerrajería",
  "Cuidado de niños",
  "Cuidado de adultos mayores",
  "Enseñanza particular",
  "Preparación de alimentos",
  "Lavado y planchado de ropa",
  "Reparación de calzado",
  "Diseño gráfico",
  "Fotografía",
  "Maquillaje profesional",
  "Decoración de eventos",
  "Servicio de meseros",
  "Vigilancia y seguridad",
  "Reparación de computadoras",
  "Desarrollo web",
  "Traducción e interpretación",
  "Contabilidad",
  "Asesoría legal",
  "Community management",
  "Entrenamiento personal",
  "Fisioterapia a domicilio",
  "Masajes terapéuticos",
  "Reparación de bicicletas",
  "Entrenamiento deportivo",
  "Acondicionamiento físico",
  "Entrenamiento personal físico",
  "Fumigación",
  "Reparación de techos",
  "Instalación de paneles solares",
  "Servicios de mudanza",
  "Reparación de celulares",
  "Limpieza de piscinas",
  "Paseo de perros",
  "Servicios de catering",
  "Reparación de motocicletas",
  "Instalación de sistemas de seguridad",
  "Servicios de mensajería",
  "Clases de música",
  "Consultoría"
];

export default skills;
  
  // This list can be expanded or modified as needed.
  // Consider organizing skills into categories if the list becomes very long.