import React, { useState, useEffect } from 'react';
import { auth, db } from '../firebase';
import { 
  createUserWithEmailAndPassword, 
  signInWithEmailAndPassword, 
  GoogleAuthProvider,
  signInWithPopup,
  updateProfile
} from 'firebase/auth';
import { doc, setDoc, getDoc } from 'firebase/firestore';
import { FcGoogle } from 'react-icons/fc';
import { Link } from 'react-router-dom';
import { authModalTranslations } from '../translations';
import { sendErrorToTelegram } from '../utils/telegramErrorLogger';
import { logAnalyticsEvent } from '../utils/analytics';
import {logger} from '../utils/logger';

const AuthModal = ({ mode, onClose, onSwitchMode, customMessage }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [error, setError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Constants for validation
  const MAX_NAME_LENGTH = 50;
  const MIN_NAME_LENGTH = 2;

  // Input validation functions
  const isValidEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const isStrongPassword = (password) => {
    return password.length >= 8;
  };

  const isValidName = (name) => {
    const nameRegex = /^[a-zA-Z\u00C0-\u017F\s'-]+$/;
    return nameRegex.test(name) && name.length >= MIN_NAME_LENGTH && name.length <= MAX_NAME_LENGTH;
  };

  const isValidPhoneNumber = (phone) => {
    return /^\d{10}$/.test(phone);
  };

  // Sanitize and validate name input
  const sanitizeName = (name) => {
    return name.replace(/[^a-zA-Z\u00C0-\u017F\s'-]/g, '').slice(0, MAX_NAME_LENGTH);
  };

  // Validate form
  const validateForm = () => {
    if (!isValidEmail(email)) {
      setError(authModalTranslations.errors.invalidEmail);
      return false;
    }
    if (mode === 'signup') {
      if (!isStrongPassword(password)) {
        setError(authModalTranslations.errors.weakPassword);
        return false;
      }
      if (!isValidName(firstName)) {
        setError(authModalTranslations.errors.invalidFirstName);
        return false;
      }
      if (!isValidName(lastName)) {
        setError(authModalTranslations.errors.invalidLastName);
        return false;
      }
      if (!isValidPhoneNumber(phoneNumber)) {
        setError(authModalTranslations.errors.invalidPhoneNumber);
        return false;
      }
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isSubmitting) return;
    if (!validateForm()) return;

    setIsSubmitting(true);
    setError('');

    try {
      if (mode === 'signup') {
        const userCredential = await createUserWithEmailAndPassword(auth, email.trim(), password);
        const user = userCredential.user;
        
        await updateProfile(user, {
          displayName: `${firstName}`
        });

        await setDoc(doc(db, 'users', user.uid), {
          firstName,
          lastName,
          email: user.email,
          phoneNumber,
          isWorker: false,
          createdAt: new Date()
        });

        logger.log('User registered and profile created successfully');

         // Log signup event
         logAnalyticsEvent('sign_up', {
          method: 'email',
          user_id: user.uid
        });

      } else {
        await signInWithEmailAndPassword(auth, email.trim(), password);
        logger.log('User signed in successfully');

          // Log login event
          logAnalyticsEvent('login', {
            method: 'email',
            user_id: auth.currentUser.uid
          });
      }
      onClose();
    } catch (error) {
      logger.error('Error during authentication:', error);
      setError(error.message);

       // Send error to Telegram
       sendErrorToTelegram(`Authentication error in ${mode} mode`, {
        errorMessage: error.message,
        email: email, 
      });

    } finally {
      setIsSubmitting(false);
    }
  };

  const handleGoogleSignIn = async () => {
    if (isSubmitting) return;
    setIsSubmitting(true);
    setError('');

    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      
      // Check if the user document already exists in Firestore
      const userDocRef = doc(db, 'users', user.uid);
      const userDocSnap = await getDoc(userDocRef);

      if (!userDocSnap.exists()) {
        // This is a new user, create a profile
        const userData = {
          userId: user.uid,  // Add the userId to the document
          firstName: user.displayName ? user.displayName.split(' ')[0] : '',
          lastName: user.displayName ? user.displayName.split(' ').slice(1).join(' ') : '',
          email: user.email,
          phoneNumber: user.phoneNumber || '',
          isWorker: false,
          createdAt: new Date()
        };

        await setDoc(userDocRef, userData);
        logger.log('New user profile created with ID:', user.uid);

         // Log signup event for new Google user
         logAnalyticsEvent('sign_up', {
          method: 'google',
          user_id: user.uid
        });
      } else {
        // Existing user, but let's ensure the userId is in the document
        const existingData = userDocSnap.data();
        if (!existingData.userId) {
          await setDoc(userDocRef, { userId: user.uid }, { merge: true });
          logger.log('Updated existing user profile with ID:', user.uid);
        }
        logger.log('Existing user signed in:', user.uid);
        
         // Log login event for existing Google user
         logAnalyticsEvent('login', {
          method: 'google',
          user_id: user.uid
        });
      }

      logger.log('User signed in with Google successfully');
      onClose();
    } catch (error) {
      logger.error('Error during Google sign-in:', error);
      setError(error.message);

       // Send error to Telegram
       sendErrorToTelegram(`Authentication error in ${mode} mode`, {
        errorMessage: error.message,
        email: email, 
      });

    } finally {
      setIsSubmitting(false);
    }
  };
  
  // Reset error when email or password changes
  useEffect(() => {
    setError('');
  }, [email, password, firstName, lastName, phoneNumber]);

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full" id="my-modal">
      <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
        <div className="mt-3 text-center">
          {customMessage && (
            <p className="text-sm text-gray-600 mb-4">{customMessage}</p>
          )}
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            {mode === 'signup' ? authModalTranslations.titles.signup : authModalTranslations.titles.signin}
          </h3>
          <form onSubmit={handleSubmit} className="mt-2 space-y-4">
            {mode === 'signup' && (
              <>
                <input
                  type="text"
                  value={firstName}
                  onChange={(e) => setFirstName(sanitizeName(e.target.value))}
                  placeholder={authModalTranslations.placeholders.firstName}
                  className="w-full px-3 py-2 border rounded-md"
                  required
                  maxLength={MAX_NAME_LENGTH}
                />
                <input
                  type="text"
                  value={lastName}
                  onChange={(e) => setLastName(sanitizeName(e.target.value))}
                  placeholder={authModalTranslations.placeholders.lastName}
                  className="w-full px-3 py-2 border rounded-md"
                  required
                  maxLength={MAX_NAME_LENGTH}
                />
                <input
                  type="tel"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value.replace(/\D/g, '').slice(0, 10))}
                  placeholder={authModalTranslations.placeholders.phoneNumber}
                  className="w-full px-3 py-2 border rounded-md"
                  required
                />
              </>
            )}
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder={authModalTranslations.placeholders.email}
              className="w-full px-3 py-2 border rounded-md"
              required
            />
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder={authModalTranslations.placeholders.password}
              className="w-full px-3 py-2 border rounded-md"
              required
            />
            {error && <p className="text-red-500 text-sm">{error}</p>}
            <button 
              type="submit" 
              className="w-full px-4 py-2 text-white bg-blue-500 rounded-md hover:bg-blue-600 disabled:bg-blue-300"
              disabled={isSubmitting}
            >
              {isSubmitting ? authModalTranslations.buttons.processing : (mode === 'signup' ? authModalTranslations.buttons.createAccount : authModalTranslations.buttons.signIn)}
            </button>
          </form>
          {mode === 'signup' && (
            <p className="mt-2 text-xs text-gray-600">
              {authModalTranslations.messages.agreeToTerms}{' '}
              <Link to="/terms-of-service" className="text-blue-500 hover:underline">{authModalTranslations.links.termsOfService}</Link> {authModalTranslations.messages.and}{' '}
              <Link to="/privacy-policy" className="text-blue-500 hover:underline">{authModalTranslations.links.privacyPolicy}</Link>.
            </p>
          )}
          <button
            onClick={handleGoogleSignIn}
            className="mt-4 w-full py-2 px-4 border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 flex items-center justify-center disabled:bg-gray-100"
            disabled={isSubmitting}
          >
            <FcGoogle className="mr-2" size={20} />
            {mode === 'signup' ? authModalTranslations.buttons.signUpWithGoogle : authModalTranslations.buttons.signInWithGoogle}
          </button>
          <p className="mt-4 text-sm">
            {mode === 'signup' ? authModalTranslations.messages.alreadyHaveAccount : authModalTranslations.messages.dontHaveAccount}
            <button
              onClick={() => onSwitchMode(mode === 'signup' ? 'signin' : 'signup')}
              className="ml-1 text-blue-500 hover:underline"
            >
              {mode === 'signup' ? authModalTranslations.buttons.signIn : authModalTranslations.buttons.createAccount}
            </button>
          </p>
          <button
            onClick={onClose}
            className="mt-4 px-4 py-2 bg-gray-300 text-gray-800 rounded-md hover:bg-gray-400"
          >
            {authModalTranslations.buttons.close}
          </button>
        </div>
      </div>
    </div>
  );
};

export default AuthModal;
