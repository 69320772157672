import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { getUserProfileByUserId, getWorkerReviews } from '../utils/firestoreOperations';
import ReviewForm from './ReviewForm';
import AuthModal from './AuthModal';
import ChatModal from './ChatModal';
import { Edit, MapPin, Briefcase, DollarSign, Star, Clock, Loader, User, MessageCircle } from 'lucide-react';
import { workerProfileTranslations } from '../translations';
import { sendErrorToTelegram } from '../utils/telegramErrorLogger';
import { logAnalyticsEvent } from '../utils/analytics';
import {logger} from '../utils/logger';

const formatCurrency = (amount) => {
  if (amount == null || isNaN(amount)) return 'Not specified';
  return new Intl.NumberFormat('es-CO', {
    style: 'currency',
    currency: 'COP',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  }).format(amount).replace('$', 'COP ');
};

const getImageUrl = (photoUrl) => {
  if (photoUrl && photoUrl.startsWith('http')) return photoUrl;
  if (photoUrl) return `${process.env.PUBLIC_URL}/${photoUrl}`;
  return `${process.env.PUBLIC_URL}/default-avatar.png`;
};

const WorkerProfileDisplay = ({ isOwnProfile, onEditClick }) => {
  const [profile, setProfile] = useState(null);
  const [showContact, setShowContact] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');
  const [reviews, setReviews] = useState([]);
  const [isLoadingReviews, setIsLoadingReviews] = useState(false);
  const [reviewsError, setReviewsError] = useState('');
  const [showAuthModal, setShowAuthModal] = useState(false);
  const [authMode, setAuthMode] = useState('signin');
  const [showChatModal, setShowChatModal] = useState(false);

  const { userId } = useParams();
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProfile = async () => {
      if (!userId) {
        setError('No profile ID provided');
        setIsLoading(false);
        return;
      }

      try {
        setIsLoading(true);
        const fetchedProfile = await getUserProfileByUserId(userId);
        if (fetchedProfile) {
          setProfile(fetchedProfile);
          if (fetchedProfile.isWorker) {
            fetchReviews(userId);
          }
        } else {
          setError('Profile not found');
        }
      } catch (err) {
        logger.error('Error fetching profile:', err);
        setError('Failed to load profile. Please try again.');
        sendErrorToTelegram('Error fetching profile', {
          functionName: 'fetchProfile',
          fileName: 'WorkerProfileDisplay.js',
          userId,
          errorMessage: err.message
        });
      } finally {
        setIsLoading(false);
      }
    };

    fetchProfile();
  }, [userId]);

  const fetchReviews = async (workerId) => {
    setIsLoadingReviews(true);
    try {
      const fetchedReviews = await getWorkerReviews(workerId);
      setReviews(fetchedReviews);
    } catch (error) {
      logger.error('Error fetching reviews:', error);
      setReviewsError('Failed to load reviews. Please try again.');
      sendErrorToTelegram('Error fetching reviews', {
        functionName: 'fetchReviews',
        fileName: 'WorkerProfileDisplay.js',
        workerId,
        errorMessage: error.message
      });
    } finally {
      setIsLoadingReviews(false);
    }
  };

  const handleRevealContact = () => {
    logger.log('Attempting to reveal contact information');
    try {
      if (currentUser) {
        setShowContact(true);
        logAnalyticsEvent('contact_info_revealed', {
          worker_id: userId,
          user_id: currentUser.uid
        });
      } else {
        setShowAuthModal(true);
        setAuthMode('signin');
      }
    } catch (error) {
      logger.error('Error revealing contact information:', error);
      setError('Failed to reveal contact information. Please try again.');
    }
  };

  const handleAuthSuccess = () => {
    setShowAuthModal(false);
    setShowContact(true);
  };

  const handleChatClick = () => {
    if (currentUser) {
      setShowChatModal(true);
      logAnalyticsEvent('chat_initiated', {
        worker_id: userId,
        user_id: currentUser.uid
      });
    } else {
      setShowAuthModal(true);
      setAuthMode('signin');
    }
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-64">
        <Loader className="animate-spin text-blue-500" size={48} />
      </div>
    );
  }

  if (error) return <div className="text-center py-4 text-red-500">{error}</div>;
  if (!profile) return <div className="text-center py-4">Profile not found. <button onClick={() => navigate('/')} className="text-blue-500 underline">Go back to home</button></div>;

  // The return statement and JSX will follow here...

  return (
    <div className="max-w-4xl mx-auto p-4">
      {/* Profile Header */}
      <div className="flex flex-col sm:flex-row items-center sm:items-start justify-between mb-6">
        <div className="flex flex-col sm:flex-row items-center sm:items-start mb-4 sm:mb-0">
          <img
            src={getImageUrl(profile.photoUrl)}
            alt={`${profile.firstName}'s profile`}
            className="w-24 h-24 rounded-full object-cover mb-2 sm:mb-0 sm:mr-4"
          />
          <div className="text-center sm:text-left">
            <h2 className="text-2xl font-bold">{profile.firstName} {profile.isWorker}</h2>
            {profile.isWorker && <p className="text-gray-600">{profile.skill || workerProfileTranslations.notSpecified}</p>}
          </div>
        </div>
        {isOwnProfile && (
          <button
            onClick={onEditClick}
            className="flex items-center px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition duration-300"
          >
            <Edit size={18} className="mr-2" />
            {workerProfileTranslations.editProfile}
          </button>
        )}
      </div>

      {profile.isWorker ? (
        // Worker Profile Content
        <>
          {/* Basic Info */}
          <div className="flex flex-col sm:flex-row sm:flex-wrap gap-4 mb-6 text-gray-600">
            <div className="flex items-center">
              <MapPin size={18} className="mr-2 flex-shrink-0" />
              <span>{profile.city || workerProfileTranslations.notSpecified}</span>
            </div>
            <div className="flex items-center">
              <Briefcase size={18} className="mr-2 flex-shrink-0" />
              <span>{profile.experience || workerProfileTranslations.notSpecified} {workerProfileTranslations.yearsExperience}</span>
            </div>
            <div className="flex items-center">
              <DollarSign size={18} className="mr-2 flex-shrink-0" />
              <span>{formatCurrency(profile.hourlyRate)} {workerProfileTranslations.currencyPerHour}</span>
            </div>
          </div>

          {/* Introduction */}
          {profile.introduction && (
            <section className="mb-6">
              <h3 className="text-xl font-semibold mb-2">{workerProfileTranslations.introduction}</h3>
              <p className="text-gray-700">{profile.introduction}</p>
            </section>
          )}

          {/* Specializations */}
          <section className="mb-6">
            <h3 className="text-xl font-semibold mb-2">{workerProfileTranslations.specializations}</h3>
            <div className="flex flex-wrap gap-2">
              {Array.isArray(profile.tags) && profile.tags.length > 0 ? (
                profile.tags.map(tag => (
                  <span key={tag} className="px-3 py-1 bg-blue-100 text-blue-800 text-sm font-medium rounded-full">
                    {tag}
                  </span>
                ))
              ) : (
                <span className="text-gray-500">{workerProfileTranslations.noSpecializations}</span>
              )}
            </div>
          </section>

        {/* Contact Information */}
        <section className="mb-6">
          <h3 className="text-xl font-semibold mb-2">{workerProfileTranslations.contactInformation}</h3>
          {!isOwnProfile && (
            <>
              {profile.contactPreference === 'reveal' ? (
                showContact ? (
                  <div>
                    <p>{workerProfileTranslations.email} {profile.email || workerProfileTranslations.notProvided}</p>
                    <p>{workerProfileTranslations.phone} {profile.phoneNumber || workerProfileTranslations.notProvided}</p>
                  </div>
                ) : (
                  <button
                    onClick={currentUser ? handleRevealContact : () => setShowAuthModal(true)}
                    className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition duration-300"
                  >
                    {workerProfileTranslations.revealContactInfo}
                  </button>
                )
              ) : (
                <button
                  onClick={currentUser ? handleChatClick : () => setShowAuthModal(true)}
                  className="flex items-center px-4 py-2 bg-green-500 text-white rounded-md hover:bg-green-600 transition duration-300"
                >
                  <MessageCircle size={18} className="mr-2" />
                  {workerProfileTranslations.chatWithWorker}
                </button>
              )}
            </>
          )}
          {isOwnProfile && (
            <div>
              <p>{workerProfileTranslations.email} {profile.email || workerProfileTranslations.notProvided}</p>
              <p>{workerProfileTranslations.phone} {profile.phoneNumber || workerProfileTranslations.notProvided}</p>
            </div>
          )}
        </section>

          {/* Reviews */}
          <section>
            <h3 className="text-xl font-semibold mb-4">{workerProfileTranslations.reviews}</h3>
            {isLoadingReviews ? (
              <div className="flex justify-center items-center h-24">
                <Loader className="animate-spin text-blue-500" size={24} />
              </div>
            ) : reviewsError ? (
              <p className="text-red-500">{reviewsError}</p>
            ) : reviews.length > 0 ? (
              <div className="space-y-4">
                {reviews.map((review) => (
                  <div key={review.id} className="border-b pb-4 last:border-b-0">
                    <div className="flex items-center mb-2">
                      {[...Array(5)].map((_, i) => (
                        <Star
                          key={i}
                          size={18}
                          className={i < review.rating ? "text-yellow-400" : "text-gray-300"}
                          fill={i < review.rating ? "currentColor" : "none"}
                        />
                      ))}
                      <span className="ml-2 font-semibold">{review.rating} / 5</span>
                    </div>
                    <p className="text-gray-700 mb-2">{review.comment}</p>
                    <div className="text-sm text-gray-500 flex items-center justify-between">
                      <div className="flex items-center">
                        <Clock size={14} className="mr-1 flex-shrink-0" />
                        <span>{new Date(review.createdAt.seconds * 1000).toLocaleDateString()}</span>
                      </div>
                      <div className="flex items-center">
                        <User size={14} className="mr-1 flex-shrink-0" />
                        <span className="font-medium text-blue-600">
                          {review.reviewerName || workerProfileTranslations.anonymousUser}
                        </span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <p>{workerProfileTranslations.noReviewsYet}</p>
            )}

            {currentUser && currentUser.uid !== userId && (
              <div className="mt-6">
                <ReviewForm userId={userId} onReviewSubmitted={() => fetchReviews(userId)} />
              </div>
            )}
          </section>
        </>
      ) : (
        // Non-Worker (Client) Profile Content
        <div className="bg-white shadow rounded-lg p-6">
          <h3 className="text-xl font-semibold mb-4">{workerProfileTranslations.basicInfo}</h3>
          <div className="space-y-2">
            <p><strong>{workerProfileTranslations.email}:</strong> {isOwnProfile || showContact ? profile.email : workerProfileTranslations.hidden}</p>
            <p><strong>{workerProfileTranslations.phone}:</strong> {isOwnProfile || showContact ? (profile.phoneNumber || workerProfileTranslations.notProvided) : workerProfileTranslations.hidden}</p>
          </div>
          {!isOwnProfile && currentUser && !showContact && (
            <button
              onClick={handleRevealContact}
              className="mt-4 px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition duration-300"
            >
              {workerProfileTranslations.revealContactInfo}
            </button>
          )}
        </div>
      )}

      {/* AuthModal */}
      {showAuthModal && (
        <AuthModal
          mode={authMode}
          onClose={() => setShowAuthModal(false)}
          onSwitchMode={(newMode) => setAuthMode(newMode)}
          onAuthSuccess={handleAuthSuccess}
          customMessage={workerProfileTranslations.authModalMessage}
        />
      )}

      {/* ChatModal */}
      {showChatModal && (
        <ChatModal
          workerId={userId}
          workerName={`${profile.firstName} ${profile.lastName || ''}`}
          onClose={() => setShowChatModal(false)}
        />
      )}
    </div>
  );
};

export default WorkerProfileDisplay;
