import React from 'react';
import ReactMarkdown from 'react-markdown';

const privacyPolicyContent = `


Ultima actualización: [Current Date]

# Política de Privacidad para la Plataforma Service Connect

Última actualización: [Fecha Actual]

## 1. Introducción

Bienvenido a Service Connect ("nosotros", "nuestro" o "nos"). Estamos comprometidos con la protección de su privacidad y datos personales. Esta Política de Privacidad explica cómo recolectamos, usamos, divulgamos y protegemos su información cuando utiliza nuestro sitio web, aplicación móvil y cualquier servicio relacionado (colectivamente, la "Plataforma").

Esta Política de Privacidad se rige por la Ley Estatutaria 1581 de 2012, el Decreto 1377 de 2013, y demás normas que los modifiquen, complementen o adicionen.

Por favor, lea cuidadosamente esta Política de Privacidad. Si no está de acuerdo con los términos de esta Política de Privacidad, por favor no acceda a la Plataforma. Al usar la Plataforma, usted acepta la recolección, uso y divulgación de su información como se describe en esta Política de Privacidad.

## 2. Responsable del Tratamiento de Datos

[Nombre de su Empresa]
[Dirección]
[Correo electrónico]
[Teléfono]

## 3. Información que Recolectamos

Recolectamos varios tipos de información de y sobre los usuarios de nuestra Plataforma, incluyendo:

### 3.1 Datos Personales

- Información de contacto (como nombre, dirección de correo electrónico, número de teléfono)
- Información de perfil (como habilidades, experiencia, ubicación)
- Credenciales de inicio de sesión de la cuenta
- Comunicación entre usuarios en la Plataforma
- Contenido generado por el usuario (como reseñas, calificaciones y comentarios)

### 3.2 Información No Personal

- Datos de uso (como dirección IP, tipo de navegador, información del dispositivo)
- Datos de ubicación (si lo permiten las configuraciones de su dispositivo)
- Datos de registro (como tiempos de acceso, páginas vistas y actividad del sistema)

## 4. Cómo Recolectamos Información

Recolectamos información de las siguientes maneras:

- Directamente de usted cuando nos la proporciona (por ejemplo, cuando se registra, actualiza su perfil o se comunica con otros usuarios)
- Automáticamente mientras navega por la Plataforma (utilizando cookies y otras tecnologías de seguimiento)

## 5. Finalidades del Tratamiento de Datos

Utilizamos la información que recolectamos sobre usted para varios propósitos, incluyendo:

- Proporcionar, mantener y mejorar nuestra Plataforma
- Facilitar conexiones entre proveedores de servicios y clientes
- Comunicarnos con usted sobre su cuenta y nuestros servicios
- Personalizar su experiencia en la Plataforma
- Analizar patrones de uso y tendencias
- Detectar, prevenir y abordar fraudes o problemas de seguridad
- Cumplir con obligaciones legales

## 6. Divulgación de su Información

Podemos divulgar su información personal en las siguientes circunstancias:

- A otros usuarios de la Plataforma según sea necesario para facilitar conexiones (por ejemplo, los proveedores de servicios pueden ver las solicitudes de los clientes, y los clientes pueden ver los perfiles de los proveedores de servicios)
- A nuestras subsidiarias y afiliadas
- A contratistas, proveedores de servicios y otros terceros que utilizamos para apoyar nuestro negocio
- Para cumplir con cualquier orden judicial, ley o proceso legal, incluida la respuesta a cualquier solicitud gubernamental o regulatoria
- Para hacer cumplir nuestros Términos y Condiciones u otros acuerdos
- Si creemos que la divulgación es necesaria para proteger los derechos, la propiedad o la seguridad de Service Connect, nuestros usuarios u otros

## 7. Sus Derechos y Opciones

De acuerdo con la Ley 1581 de 2012, usted tiene los siguientes derechos con respecto a sus datos personales:

- Conocer, actualizar y rectificar sus datos personales
- Solicitar prueba de la autorización otorgada para el tratamiento de sus datos
- Ser informado sobre el uso que se ha dado a sus datos personales
- Presentar quejas ante la Superintendencia de Industria y Comercio por infracciones a la ley
- Revocar la autorización y/o solicitar la supresión de sus datos cuando el tratamiento no respete los principios, derechos y garantías constitucionales y legales
- Acceder gratuitamente a sus datos personales que hayan sido objeto de tratamiento

Para ejercer estos derechos, por favor contáctenos utilizando la información proporcionada en la sección "Responsable del Tratamiento de Datos".

## 8. Retención de Datos

Conservaremos su información personal durante el tiempo que sea necesario para cumplir con los propósitos descritos en esta Política de Privacidad, a menos que la ley requiera o permita un período de retención más largo.

## 9. Seguridad de Datos

Hemos implementado medidas diseñadas para proteger su información personal contra pérdidas accidentales y accesos, usos, alteraciones y divulgaciones no autorizados. Sin embargo, ningún método de transmisión por Internet o de almacenamiento electrónico es 100% seguro, y no podemos garantizar la seguridad absoluta.

## 10. Privacidad de Menores

Nuestra Plataforma no está destinada a menores de 18 años. No recopilamos conscientemente información personal de menores de 18 años. Si es padre o tutor y cree que su hijo nos ha proporcionado información personal, por favor contáctenos.

## 11. Transferencias Internacionales de Datos

Su información puede ser transferida y procesada en países distintos al país en el que reside. Estos países pueden tener leyes de protección de datos diferentes a las leyes de su país. Al utilizar la Plataforma, usted consiente la transferencia de su información a países fuera de Colombia.

Nos aseguraremos de que cualquier transferencia internacional de datos cumpla con las leyes de protección de datos aplicables y que se implementen las salvaguardias adecuadas.

## 12. Enlaces y Servicios de Terceros

Nuestra Plataforma puede contener enlaces a sitios web y servicios de terceros. No somos responsables del contenido o las prácticas de privacidad de estos terceros. Le recomendamos que revise las políticas de privacidad de cualquier sitio de terceros que visite.

## 13. Cambios en Nuestra Política de Privacidad

Podemos actualizar nuestra Política de Privacidad de vez en cuando. Le notificaremos cualquier cambio publicando la nueva Política de Privacidad en esta página y actualizando la fecha de "Última actualización". Se le aconseja revisar esta Política de Privacidad periódicamente para cualquier cambio.

## 14. Autoridad de Protección de Datos

La autoridad de protección de datos en Colombia es la Superintendencia de Industria y Comercio (SIC). Si tiene alguna queja sobre cómo manejamos sus datos personales, tiene derecho a presentar una queja ante la SIC.

## 15. Política de Cookies

Nuestra Plataforma utiliza cookies y tecnologías de seguimiento similares para mejorar su experiencia de navegación y analizar patrones de uso. Puede configurar su navegador para rechazar todas o algunas cookies del navegador, o para alertarle cuando los sitios web configuran o acceden a las cookies. Si deshabilita o rechaza las cookies, tenga en cuenta que algunas partes de la Plataforma pueden volverse inaccesibles o no funcionar correctamente.

Al utilizar nuestra Plataforma, usted reconoce que ha leído y entendido esta Política de Privacidad y acepta estar obligado por sus términos.
`;

const PrivacyPolicy = () => {
  console.log("Rendering PrivacyPolicy component");
  console.log("Content:", privacyPolicyContent);

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="prose prose-sm sm:prose lg:prose-lg xl:prose-xl max-w-none">
        <ReactMarkdown
          components={{
            h1: ({node, ...props}) => <h1 style={{color: '#2c3e50', fontSize: '2.5em', marginBottom: '0.5em'}} {...props} />,
            h2: ({node, ...props}) => <h2 style={{color: '#34495e', fontSize: '2em', marginBottom: '0.5em'}} {...props} />,
            h3: ({node, ...props}) => <h3 style={{color: '#7f8c8d', fontSize: '1.5em', marginBottom: '0.5em'}} {...props} />,
            p: ({node, ...props}) => <p style={{marginBottom: '1em', lineHeight: '1.6'}} {...props} />,
            ul: ({node, ...props}) => <ul style={{marginBottom: '1em', paddingLeft: '2em'}} {...props} />,
            ol: ({node, ...props}) => <ol style={{marginBottom: '1em', paddingLeft: '2em'}} {...props} />,
            li: ({node, ...props}) => <li style={{marginBottom: '0.5em'}} {...props} />,
          }}
        >
          {privacyPolicyContent}
        </ReactMarkdown>
      </div>
    </div>
  );
};

export default PrivacyPolicy;