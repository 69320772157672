import React from 'react';
import { aboutUsTranslations } from '../translations';

const AboutUs = () => {
  return (
    <div className="max-w-4xl mx-auto p-4">
      <h1 className="text-3xl font-bold text-center mb-8">
        {aboutUsTranslations.title}
      </h1>

      <section className="mb-12">
        <h2 className="text-2xl font-semibold mb-4">
          {aboutUsTranslations.mission.title}
        </h2>
        <p className="text-lg text-gray-700">
          {aboutUsTranslations.mission.description}
        </p>
      </section>

      <section className="mb-12">
        <h2 className="text-2xl font-semibold mb-4">
          {aboutUsTranslations.values.title}
        </h2>
        <ul className="list-disc list-inside text-lg text-gray-700">
          {aboutUsTranslations.values.list.map((value, index) => (
            <li key={index} className="mb-2">{value}</li>
          ))}
        </ul>
      </section>


      <section>
        <h2 className="text-2xl font-semibold mb-4">
          {aboutUsTranslations.contact.title}
        </h2>
        <p className="text-lg text-gray-700">
          {aboutUsTranslations.contact.description}
        </p>
        <a
          href={`mailto:${aboutUsTranslations.contact.email}`}
          className="inline-block mt-4 px-6 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition duration-300"
        >
          {aboutUsTranslations.contact.buttonText}
        </a>
      </section>
    </div>
  );
};

export default AboutUs;