const specializations = [
  // Limpieza y mantenimiento
  "Limpieza residencial",
  "Limpieza comercial",
  "Limpieza industrial",
  "Limpieza de alfombras",
  "Lavado de ventanas",
  "Limpieza de ductos",
  "Limpieza de fachadas",
  "Limpieza post-construcción",
  "Desinfección de espacios",
  "Limpieza de vehículos",

  // Carpintería y ebanistería
  "Carpintería de muebles",
  "Ebanistería fina",
  "Restauración de muebles antiguos",
  "Carpintería de obra blanca",
  "Diseño y fabricación de cocinas",
  "Fabricación de puertas y ventanas",
  "Carpintería de exteriores",
  "Tallado en madera",
  "Instalación de pisos de madera",
  "Fabricación de estructuras de madera",

  // Plomería
  "Instalación de tuberías",
  "Reparación de fugas",
  "Instalación de sanitarios",
  "Mantenimiento de sistemas de agua",
  "Instalación de calentadores de agua",
  "Desatasco de cañerías",
  "Instalación de sistemas de riego",
  "Fontanería para piscinas",
  "Instalación de bombas de agua",
  "Reparación de grifería",

  // Electricidad
  "Instalaciones eléctricas residenciales",
  "Instalaciones eléctricas industriales",
  "Mantenimiento de sistemas eléctricos",
  "Instalación de paneles solares",
  "Reparación de electrodomésticos",
  "Instalación de iluminación",
  "Cableado estructurado",
  "Instalación de sistemas de seguridad",
  "Automatización del hogar",
  "Reparación de transformadores",

  // Albañilería y construcción
  "Construcción de muros",
  "Colocación de pisos y azulejos",
  "Estucado y enlucido",
  "Trabajos en concreto",
  "Remodelaciones integrales",
  "Construcción de chimeneas",
  "Restauración de fachadas",
  "Impermeabilización",
  "Instalación de techos",
  "Construcción de piscinas",

  // Jardinería y paisajismo
  "Diseño de jardines",
  "Mantenimiento de áreas verdes",
  "Poda de árboles",
  "Instalación de sistemas de riego",
  "Control de plagas en jardines",
  "Diseño y mantenimiento de huertos",
  "Instalación de césped artificial",
  "Paisajismo de interiores",
  "Construcción de jardines verticales",
  "Mantenimiento de campos deportivos",

  // Pintura y decoración
  "Pintura de interiores",
  "Pintura de exteriores",
  "Pintura decorativa",
  "Aplicación de texturas",
  "Restauración de pinturas",
  "Pintura industrial",
  "Pintura automotriz",
  "Aerografía",
  "Empapelado de paredes",
  "Pintura de murales",

  // Transporte y conducción
  "Conducción de taxis",
  "Servicio de conductor privado",
  "Conducción de buses escolares",
  "Transporte de carga",
  "Mudanzas",
  "Servicio de mototaxi",
  "Conducción de vehículos pesados",
  "Transporte especializado de alimentos",
  "Conducción de ambulancias",
  "Servicios de mensajería y paquetería",

  // Belleza y cuidado personal
  "Corte y peinado de cabello",
  "Coloración capilar",
  "Tratamientos capilares",
  "Manicura",
  "Pedicura",
  "Depilación",
  "Maquillaje profesional",
  "Extensiones de pestañas",
  "Microblading",
  "Tratamientos faciales",

  // Costura y moda
  "Sastrería a medida",
  "Confección de vestidos de novia",
  "Arreglos de ropa",
  "Diseño de moda",
  "Costura de tapicería",
  "Confección de disfraces",
  "Bordado artesanal",
  "Diseño y confección de uniformes",
  "Costura de ropa deportiva",
  "Restauración de prendas antiguas",

  // Reparación de electrodomésticos
  "Reparación de lavadoras",
  "Reparación de refrigeradores",
  "Mantenimiento de aires acondicionados",
  "Reparación de hornos y estufas",
  "Reparación de lavavajillas",
  "Mantenimiento de calentadores",
  "Reparación de microondas",
  "Reparación de pequeños electrodomésticos",
  "Instalación de electrodomésticos",
  "Reparación de televisores",

  // Mecánica automotriz
  "Diagnóstico automotriz",
  "Reparación de motores",
  "Mantenimiento preventivo",
  "Reparación de sistemas de frenos",
  "Reparación de sistemas de suspensión",
  "Alineación y balanceo",
  "Reparación de sistemas eléctricos automotrices",
  "Reparación de transmisiones",
  "Instalación de sistemas de sonido automotriz",
  "Reparación de carrocería y pintura",

  // Cerrajería
  "Apertura de cerraduras",
  "Instalación de cerraduras de seguridad",
  "Fabricación de llaves",
  "Reparación de cajas fuertes",
  "Instalación de sistemas de control de acceso",
  "Cerrajería automotriz",
  "Instalación de cerraduras electrónicas",
  "Reparación de cerraduras antiguas",
  "Instalación de puertas de seguridad",
  "Cerrajería para empresas",

  // Cuidado infantil y educación
  "Cuidado de bebés",
  "Tutoría académica",
  "Enseñanza de idiomas",
  "Cuidado después de la escuela",
  "Educación especial",
  "Estimulación temprana",
  "Enseñanza de música para niños",
  "Clases de arte para niños",
  "Entrenamiento deportivo infantil",
  "Asesoría en tareas escolares",

  // Cuidado de adultos mayores
  "Asistencia en actividades diarias",
  "Acompañamiento a citas médicas",
  "Cuidado de pacientes con Alzheimer",
  "Fisioterapia geriátrica",
  "Terapia ocupacional para adultos mayores",
  "Preparación de comidas especiales",
  "Asistencia en la toma de medicamentos",
  "Cuidados paliativos",
  "Terapia recreativa para adultos mayores",
  "Asistencia en la higiene personal",

  // Gastronomía y servicios alimentarios
  "Chef a domicilio",
  "Repostería especializada",
  "Catering para eventos",
  "Preparación de dietas especiales",
  "Asesoría en nutrición",
  "Barista profesional",
  "Sommelier",
  "Cocina internacional",
  "Panadería artesanal",
  "Servicio de comida para empresas",

  // Tecnología y servicios digitales
  "Desarrollo de aplicaciones móviles",
  "Diseño de páginas web",
  "Administración de redes sociales",
  "Soporte técnico informático",
  "Diseño gráfico digital",
  "Edición de video",
  "Marketing digital",
  "Análisis de datos",
  "Desarrollo de software a medida",
  "Ciberseguridad",

  // Servicios legales y financieros
  "Asesoría legal general",
  "Contabilidad para pequeñas empresas",
  "Asesoría fiscal",
  "Planificación financiera personal",
  "Mediación de conflictos",
  "Servicios notariales",
  "Asesoría en derecho laboral",
  "Gestión de nóminas",
  "Auditoría contable",
  "Asesoría en propiedad intelectual",

  // Salud y bienestar
  "Fisioterapia a domicilio",
  "Masajes terapéuticos",
  "Yoga y meditación",
  "Nutrición y dietética",
  "Terapia psicológica",
  "Entrenamiento personal",
  "Acupuntura",
  "Quiropráctica",
  "Terapia ocupacional",
  "Podología",

  // Servicios para mascotas
  "Peluquería canina",
  "Entrenamiento de perros",
  "Paseo de perros",
  "Cuidado de mascotas a domicilio",
  "Veterinaria móvil",
  "Adiestramiento de animales exóticos",
  "Fotografía de mascotas",
  "Terapia asistida con animales",
  "Guardería de mascotas",
  "Nutrición animal especializada",

  // Artes y entretenimiento
  "Fotografía de eventos",
  "Animación de fiestas infantiles",
  "DJ para eventos",
  "Clases de baile",
  "Clases de pintura",
  "Actuación y teatro",
  "Producción musical",
  "Escultura",
  "Caricaturista para eventos",
  "Narración oral y cuentacuentos",


 // Deportes
 "Clases de tenis",
 "Clases de fútbol",
 "Clases de natación",
 "Gimnasia"
];

export default specializations;