import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { 
  createOrUpdateUserProfile, 
  getUserProfileByUserId,
  deleteWorkerProfile
} from '../utils/firestoreOperations';
import colombianCities from '../data/colombianCities';
import skills from '../data/skills';
import specializations from '../data/specializations';
import { storage } from '../firebase';
import { ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';
import { AlertCircle, Loader, X, Trash2 } from 'lucide-react';
import { workerFormTranslations } from '../translations';
import { logger } from '../utils/logger';

// Constants for input validation
const MAX_NAME_LENGTH = 50;
const MAX_EXPERIENCE_YEARS = 60;
const MAX_HOURLY_RATE = 10000000;
const MAX_PHONE_LENGTH = 15;
const MAX_EMAIL_LENGTH = 100;
const MAX_TAGS = 10;
const MAX_INTRODUCTION_LENGTH = 500;

const WorkerProfileForm = ({ isCreating = false, initialProfile = null }) => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [introduction, setIntroduction] = useState('');
  const [city, setCity] = useState('');
  const [experience, setExperience] = useState('');
  const [hourlyRate, setHourlyRate] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [formattedPhone, setFormattedPhone] = useState('');
  const [photoUrl, setPhotoUrl] = useState('');
  const [photoFile, setPhotoFile] = useState(null);
  const [selectedSkill, setSelectedSkill] = useState('');
  const [selectedTags, setSelectedTags] = useState([]);
  const [tagSearch, setTagSearch] = useState('');
  const [filteredTags, setFilteredTags] = useState([]);
  const [showTagDropdown, setShowTagDropdown] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [fieldErrors, setFieldErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [isDeletingProfile, setIsDeletingProfile] = useState(false);
  const [contactPreference, setContactPreference] = useState('reveal');
  const [isWorker, setIsWorker] = useState(false);

  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const fileInputRef = useRef(null);
  const tagDropdownRef = useRef(null);

  useEffect(() => {
    const fetchProfile = async () => {
      if (!isCreating && currentUser) {
        setIsLoading(true);
        try {
          const profileData = await getUserProfileByUserId(currentUser.uid);
          logger.log('Fetched profile data in component:', JSON.stringify(profileData, null, 2));
          if (profileData) {
            setFirstName(profileData.firstName || '');
            setLastName(profileData.lastName || '');
            setIntroduction(profileData.introduction || '');
            setCity(profileData.city || '');
            setExperience(profileData.experience?.toString() || '');
            setHourlyRate(profileData.hourlyRate?.toString() || '');
            setEmail(profileData.email || '');
            setPhoneNumber(profileData.phoneNumber || '');
            setPhotoUrl(profileData.photoUrl || '');
            setSelectedSkill(profileData.skill || '');
            setSelectedTags(Array.isArray(profileData.tags) ? profileData.tags : []);
            setContactPreference(profileData.contactPreference || 'reveal');
            setIsWorker(profileData.isWorker || false);
          }
        } catch (err) {
          logger.error('Error fetching profile:', err);
          setError('Failed to load profile data. Please try again.');
        } finally {
          setIsLoading(false);
        }
      }
    };
  
    fetchProfile();
  }, [isCreating, currentUser]);

  useEffect(() => {
    if (Array.isArray(specializations)) {
      const filtered = specializations.filter(tag => 
        tag.toLowerCase().includes(tagSearch.toLowerCase()) &&
        !selectedTags.includes(tag)
      );
      setFilteredTags(filtered);
    } else {
      logger.error('specializations is not an array:', specializations);
      setFilteredTags([]);
    }
  }, [tagSearch, selectedTags]);

  // New effect to handle clicks outside the tag dropdown
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (tagDropdownRef.current && !tagDropdownRef.current.contains(event.target)) {
        setShowTagDropdown(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleHourlyRateChange = (e) => {
    const value = e.target.value;
    if (value === '' || (Number(value) >= 0 && Number(value) <= MAX_HOURLY_RATE)) {
      setHourlyRate(value);
    }
  };

  const handleTagSearch = (e) => {
    setTagSearch(e.target.value);
    setShowTagDropdown(true);
  };

  const handleTagSelect = (tag) => {
    if (selectedTags.length < MAX_TAGS) {
      setSelectedTags(prevTags => Array.isArray(prevTags) ? [...prevTags, tag] : [tag]);
      setTagSearch('');
      setShowTagDropdown(false);
    } else {
      setFieldErrors(prev => ({ ...prev, tags: `Maximum ${MAX_TAGS} tags allowed` }));
    }
  };

  const handleRemoveTag = (tag) => {
    setSelectedTags(prevTags => 
      Array.isArray(prevTags) ? prevTags.filter(t => t !== tag) : []
    );
    setFieldErrors(prev => ({ ...prev, tags: '' }));
  };

  const handleDeleteProfile = async () => {
    if (!currentUser) {
      logger.error('No authenticated user found');
      return;
    }

    setIsDeletingProfile(true);
    setError('');

    try {
      // Delete profile photo from storage if it exists
      if (photoUrl) {
        const photoRef = ref(storage, `profile-photos/${currentUser.uid}`);
        await deleteObject(photoRef);
      }

      // Delete worker profile from Firestore
      await deleteWorkerProfile(currentUser.uid);

      logger.log('Profile deleted successfully');
      navigate('/'); // Redirect to home page after deletion
    } catch (err) {
      logger.error('Error deleting profile:', err);
      setError('Failed to delete profile. Please try again.');
    } finally {
      setIsDeletingProfile(false);
      setShowDeleteConfirmation(false);
    }
  };

  const handlePhotoChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      if (file.size > 5 * 1024 * 1024) {
        setFieldErrors(prev => ({ ...prev, photo: 'File is too large. Please choose an image smaller than 5MB.' }));
        return;
      }
      setPhotoFile(file);
      setFieldErrors(prev => ({ ...prev, photo: '' }));
    }
  };

  const formatPhoneNumber = (input) => {
    const digitsOnly = input.replace(/\D/g, '');
    if (digitsOnly.length === 0) return '';
    if (digitsOnly.length <= 3) {
      return `+57 (${digitsOnly}`;
    } else if (digitsOnly.length <= 6) {
      return `+57 (${digitsOnly.slice(0, 3)}) ${digitsOnly.slice(3)}`;
    } else {
      return `+57 (${digitsOnly.slice(0, 3)}) ${digitsOnly.slice(3, 6)} ${digitsOnly.slice(6, 10)}`;
    }
  };

  const handlePhoneChange = (e) => {
    const input = e.target.value;
    const digitsOnly = input.replace(/\D/g, '');
    setPhoneNumber(digitsOnly);
    setFormattedPhone(formatPhoneNumber(digitsOnly));
  };

  const sanitizeInput = (input) => {
    return input.replace(/<[^>]*>?/gm, '').trim();
  };

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validateForm = () => {
    const errors = {};

    // Validate common fields
    if (!firstName) errors.firstName = workerFormTranslations.firstName.error;
    if (firstName.length > MAX_NAME_LENGTH) errors.firstName = workerFormTranslations.firstName.lengthError(MAX_NAME_LENGTH);

    if (!email) errors.email = workerFormTranslations.email.error;
    if (!isValidEmail(email)) errors.email = workerFormTranslations.email.invalidError;
    if (email.length > MAX_EMAIL_LENGTH) errors.email = workerFormTranslations.email.lengthError(MAX_EMAIL_LENGTH);

    if (phoneNumber && phoneNumber.length > MAX_PHONE_LENGTH) {
      errors.phoneNumber = workerFormTranslations.phoneNumber.lengthError(MAX_PHONE_LENGTH);
    }

    // Validate worker-specific fields only if isWorker is true
    if (isWorker) {
      if (!lastName) errors.lastName = workerFormTranslations.lastName.error;
      if (lastName.length > MAX_NAME_LENGTH) errors.lastName = workerFormTranslations.lastName.lengthError(MAX_NAME_LENGTH);

      if (!city) errors.city = workerFormTranslations.city.error;
      if (!colombianCities.includes(city)) errors.city = workerFormTranslations.city.invalidError;

      if (!selectedSkill) errors.skill = workerFormTranslations.skill.error;
      if (!skills.includes(selectedSkill)) errors.skill = workerFormTranslations.skill.invalidError;

      if (experience && (isNaN(experience) || experience < 0 || experience > MAX_EXPERIENCE_YEARS)) {
        errors.experience = workerFormTranslations.experience.error(MAX_EXPERIENCE_YEARS);
      }

      if (hourlyRate && (isNaN(hourlyRate) || hourlyRate < 0 || hourlyRate > MAX_HOURLY_RATE)) {
        errors.hourlyRate = workerFormTranslations.hourlyRate.error(MAX_HOURLY_RATE);
      }
    }

    setFieldErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setError('');
    setSubmitSuccess(false);
  
    if (!validateForm()) {
      setIsSubmitting(false);
      return;
    }
  
    try {
      let updatedPhotoUrl = photoUrl;
  
      if (photoFile) {
        const storageRef = ref(storage, `profile-photos/${currentUser.uid}`);
        await uploadBytes(storageRef, photoFile);
        updatedPhotoUrl = await getDownloadURL(storageRef);
      }
  
      const profileData = {
        userId: currentUser.uid,
        firstName: sanitizeInput(firstName),
        lastName: sanitizeInput(lastName),
        introduction: sanitizeInput(introduction),
        city,
        skill: selectedSkill,
        tags: selectedTags.map(sanitizeInput),
        experience: experience ? Number(experience) : null,
        hourlyRate: hourlyRate ? Number(hourlyRate) : null,
        email: sanitizeInput(email),
        phoneNumber: sanitizeInput(phoneNumber),
        contactPreference: contactPreference,
        photoUrl: updatedPhotoUrl,
        isWorker: isWorker, 
      };

      if (isWorker) {
        // Add worker-specific fields only if isWorker is true
        profileData.lastName = sanitizeInput(lastName);
        profileData.introduction = sanitizeInput(introduction);
        profileData.city = city;
        profileData.skill = selectedSkill;
        profileData.tags = selectedTags.map(sanitizeInput);
        profileData.experience = experience ? Number(experience) : null;
        profileData.hourlyRate = hourlyRate ? Number(hourlyRate) : null;
        profileData.contactPreference = contactPreference;
        profileData.photoUrl = updatedPhotoUrl;
      }
      
      await createOrUpdateUserProfile(currentUser.uid, profileData);
  
      logger.log(`Profile ${isCreating ? 'created' : 'updated'} successfully`);
      setSubmitSuccess(true);
      setTimeout(() => {
        navigate(`/profile/${currentUser.uid}`);
      }, 2000); // Redirect after 2 seconds
    } catch (err) {
      logger.error(`Error ${isCreating ? 'creating' : 'updating'} profile:`, err);
      setError(`Failed to ${isCreating ? 'create' : 'update'} profile. Please try again.`);
    } finally {
      setIsSubmitting(false);
    }
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-64">
        <Loader className="animate-spin text-blue-500" size={48} />
        <span className="ml-2">{workerFormTranslations.loading}</span>
      </div>
    );
  }

  // The return statement and JSX will follow here...

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      {submitSuccess && (
        <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative" role="alert">
          <strong className="font-bold">{workerFormTranslations.success.title}</strong>
          <span className="block sm:inline"> {isCreating ? workerFormTranslations.success.created : workerFormTranslations.success.updated}</span>
        </div>
      )}
  
      {error && (
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
          <strong className="font-bold">{workerFormTranslations.error.title}</strong>
          <span className="block sm:inline"> {error}</span>
        </div>
      )}
  
      {/* Basic fields (always visible) */}
      <div>
        <label htmlFor="firstName" className="block text-sm font-medium text-gray-700">{workerFormTranslations.firstName.label}</label>
        <input
          type="text"
          id="firstName"
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
          className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 ${
            fieldErrors.firstName ? 'border-red-500' : ''
          }`}
          required
          maxLength={MAX_NAME_LENGTH}
        />
        {fieldErrors.firstName && <p className="mt-2 text-sm text-red-600">{fieldErrors.firstName}</p>}
      </div>
  
      <div>
        <label htmlFor="email" className="block text-sm font-medium text-gray-700">{workerFormTranslations.email.label}</label>
        <input
          type="email"
          id="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 ${
            fieldErrors.email ? 'border-red-500' : ''
          }`}
          required
          maxLength={MAX_EMAIL_LENGTH}
        />
        {fieldErrors.email && <p className="mt-2 text-sm text-red-600">{fieldErrors.email}</p>}
      </div>
  
      <div>
        <label htmlFor="phoneNumber" className="block text-sm font-medium text-gray-700">
          {workerFormTranslations.phoneNumber.label}
        </label>
        <input
          type="tel"
          id="phoneNumber"
          value={phoneNumber}
          onChange={handlePhoneChange}
          className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 ${
            fieldErrors.phoneNumber ? 'border-red-500' : ''
          }`}
          maxLength={10}
        />
        {formattedPhone && (
          <p className="mt-2 text-sm text-gray-600">
            {workerFormTranslations.phoneNumber.formattedDisplay}: {formattedPhone}
          </p>
        )}
        {fieldErrors.phoneNumber && <p className="mt-2 text-sm text-red-600">{fieldErrors.phoneNumber}</p>}
      </div>
  
      {/* isWorker toggle */}
      <div className="flex items-center">
        <input
          type="checkbox"
          id="isWorker"
          checked={isWorker}
          onChange={(e) => setIsWorker(e.target.checked)}
          className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
        />
        <label htmlFor="isWorker" className="ml-2 block text-sm text-gray-900">
          {workerFormTranslations.isWorker.label}
        </label>
      </div>
  
      {/* Worker-specific fields */}
      {isWorker && (
        <>
          <div>
            <label htmlFor="lastName" className="block text-sm font-medium text-gray-700">{workerFormTranslations.lastName.label}</label>
            <input
              type="text"
              id="lastName"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 ${
                fieldErrors.lastName ? 'border-red-500' : ''
              }`}
              required
              maxLength={MAX_NAME_LENGTH}
            />
            {fieldErrors.lastName && <p className="mt-2 text-sm text-red-600">{fieldErrors.lastName}</p>}
          </div>
  
          <div>
            <label htmlFor="city" className="block text-sm font-medium text-gray-700">{workerFormTranslations.city.label}</label>
            <select
              id="city"
              value={city}
              onChange={(e) => setCity(e.target.value)}
              className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 ${
                fieldErrors.city ? 'border-red-500' : ''
              }`}
              required
            >
              <option value="">{workerFormTranslations.city.placeholder}</option>
              {colombianCities.map((cityName) => (
                <option key={cityName} value={cityName}>{cityName}</option>
              ))}
            </select>
            {fieldErrors.city && <p className="mt-2 text-sm text-red-600">{fieldErrors.city}</p>}
          </div>
  
          <div>
            <label htmlFor="introduction" className="block text-sm font-medium text-gray-700">
              {workerFormTranslations.introduction.label}
            </label>
            <textarea
              id="introduction"
              value={introduction}
              onChange={(e) => setIntroduction(e.target.value)}
              className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 ${
                fieldErrors.introduction ? 'border-red-500' : ''
              }`}
              rows="3"
              maxLength={MAX_INTRODUCTION_LENGTH}
              placeholder={workerFormTranslations.introduction.placeholder}
            />
            <p className="mt-2 text-sm text-gray-500">
              {workerFormTranslations.introduction.charactersCount(introduction.length, MAX_INTRODUCTION_LENGTH)}
            </p>
            {fieldErrors.introduction && (
              <p className="mt-2 text-sm text-red-600">{fieldErrors.introduction}</p>
            )}
          </div>
  
          <div>
            <label htmlFor="skill" className="block text-sm font-medium text-gray-700">{workerFormTranslations.skill.label}</label>
            <select
              id="skill"
              value={selectedSkill}
              onChange={(e) => setSelectedSkill(e.target.value)}
              className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 ${
                fieldErrors.skill ? 'border-red-500' : ''
              }`}
              required
            >
              <option value="">{workerFormTranslations.skill.placeholder}</option>
              {skills.map((skill) => (
                <option key={skill} value={skill}>{skill}</option>
              ))}
            </select>
            {fieldErrors.skill && <p className="mt-2 text-sm text-red-600">{fieldErrors.skill}</p>}
          </div>
  
          <div className="relative" ref={tagDropdownRef}>
            <label htmlFor="tags" className="block text-sm font-medium text-gray-700">{workerFormTranslations.specializations.label}</label>
            <input
              type="text"
              id="tags"
              value={tagSearch}
              onChange={handleTagSearch}
              onFocus={() => setShowTagDropdown(true)}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              placeholder={workerFormTranslations.specializations.placeholder}
            />
            {showTagDropdown && filteredTags.length > 0 && (
              <ul className="absolute z-10 w-full bg-white border border-gray-300 mt-1 max-h-60 overflow-auto rounded-md shadow-lg">
                {filteredTags.map(tag => (
                  <li 
                    key={tag} 
                    className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                    onClick={() => handleTagSelect(tag)}
                  >
                    {tag}
                  </li>
                ))}
              </ul>
            )}
          </div>
  
          <div className="flex flex-wrap gap-2 mt-2">
            {selectedTags.map(tag => (
              <span key={tag} className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800">
                {tag}
                <button 
                  type="button" 
                  onClick={() => handleRemoveTag(tag)}
                  className="ml-1 inline-flex items-center justify-center w-4 h-4 text-blue-400 hover:bg-blue-200 hover:text-blue-500 rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                  <X size={12} />
                </button>
              </span>
            ))}
          </div>
          {fieldErrors.tags && <p className="mt-2 text-sm text-red-600">{fieldErrors.tags}</p>}
  
          <div>
            <label htmlFor="experience" className="block text-sm font-medium text-gray-700">{workerFormTranslations.experience.label}</label>
            <input
              type="number"
              id="experience"
              value={experience}
              onChange={(e) => setExperience(e.target.value)}
              className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 ${
                fieldErrors.experience ? 'border-red-500' : ''
              }`}
              min="0"
              max={MAX_EXPERIENCE_YEARS}
            />
            {fieldErrors.experience && <p className="mt-2 text-sm text-red-600">{fieldErrors.experience}</p>}
          </div>
  
          <div>
            <label htmlFor="hourlyRate" className="block text-sm font-medium text-gray-700">{workerFormTranslations.hourlyRate.label}</label>
            <input
              type="number"
              id="hourlyRate"
              value={hourlyRate}
              onChange={handleHourlyRateChange}
              className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 ${
                fieldErrors.hourlyRate ? 'border-red-500' : ''
              }`}
              min="0"
              max={MAX_HOURLY_RATE}
            />
            {fieldErrors.hourlyRate && <p className="mt-2 text-sm text-red-600">{fieldErrors.hourlyRate}</p>}
          </div>
  
          <div>
            <label htmlFor="contactPreference" className="block text-sm font-medium text-gray-700">
              {workerFormTranslations.contactPreference.label}
            </label>
            <select
              id="contactPreference"
              value={contactPreference}
              onChange={(e) => setContactPreference(e.target.value)}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            >
              <option value="reveal">{workerFormTranslations.contactPreference.reveal}</option>
              <option value="chat">{workerFormTranslations.contactPreference.chat}</option>
            </select>
          </div>
  
          <div>
            <label htmlFor="photo" className="block text-sm font-medium text-gray-700">{workerFormTranslations.photo.label}</label>
            <input
              type="file"
              id="photo"
              accept="image/*"
              onChange={handlePhotoChange}
              ref={fileInputRef}
              className="mt-1 block w-full text-sm text-gray-500
                file:mr-4 file:py-2 file:px-4
                file:rounded-full file:border-0
                file:text-sm file:font-semibold
                file:bg-blue-50 file:text-blue-700
                hover:file:bg-blue-100"
            />
            {photoUrl && (
              <img src={photoUrl} alt="Profile" className="mt-2 w-32 h-32 object-cover rounded-full" />
            )}
            {fieldErrors.photo && <p className="mt-2 text-sm text-red-600">{fieldErrors.photo}</p>}
          </div>
        </>
      )}
  
      <div className="flex items-center justify-between">
        <button
          type="submit"
          disabled={isSubmitting}
          className={`inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 ${
            isSubmitting ? 'opacity-50 cursor-not-allowed' : ''
          }`}
        >
          {isSubmitting ? (
            <>
              <Loader className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" />
              {workerFormTranslations.submit.processing}
            </>
          ) : (
            isCreating ? workerFormTranslations.submit.create : workerFormTranslations.submit.update
          )}
        </button>

        {!isCreating && (
          <button
            type="button"
            onClick={() => setShowDeleteConfirmation(true)}
            className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
          >
            <Trash2 className="mr-2 h-5 w-5" />
            {workerFormTranslations.delete.button}
          </button>
        )}
      </div>

      {showDeleteConfirmation && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center">
          <div className="bg-white p-5 rounded-lg shadow-xl">
            <h3 className="text-lg font-bold mb-2">{workerFormTranslations.delete.confirmTitle}</h3>
            <p className="mb-4">{workerFormTranslations.delete.confirmMessage}</p>
            <div className="flex justify-end space-x-2">
              <button
                onClick={() => setShowDeleteConfirmation(false)}
                className="px-4 py-2 bg-gray-300 text-gray-800 rounded hover:bg-gray-400"
              >
                {workerFormTranslations.delete.cancel}
              </button>
              <button
                onClick={handleDeleteProfile}
                disabled={isDeletingProfile}
                className="px-4 py-2 bg-red-600 text-white rounded hover:bg-red-700 disabled:opacity-50"
              >
                {isDeletingProfile ? workerFormTranslations.delete.processing : workerFormTranslations.delete.confirm}
              </button>
            </div>
          </div>
        </div>
      )}
    </form>
  );
};

export default WorkerProfileForm;
