import React, { useState, useEffect, useCallback } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { realtimeDb } from '../firebase';
import { ref, onValue, off, push, get, update } from 'firebase/database';
import { Search, Send, Loader, ArrowLeft, MessageSquare } from 'lucide-react';
import { userDataService } from '../services/UserDataService';
import { useNavigate } from 'react-router-dom';
import { messagesPageTranslations, chatModalTranslations } from '../translations';
import { sendErrorToTelegram } from '../utils/telegramErrorLogger';
import AuthModal from '../components/AuthModal';
import {logger} from '../utils/logger';

const MessagesPage = () => {
  // State declarations
  const [conversations, setConversations] = useState([]);
  const [selectedConversation, setSelectedConversation] = useState(null);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [activeTab, setActiveTab] = useState('conversations');
  const [showAuthModal, setShowAuthModal] = useState(false);

  // Hooks
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  // Fetch conversations
  const fetchConversations = useCallback(async () => {
    if (!currentUser) {
      logger.log("No current user, showing auth modal");
      setShowAuthModal(true);
      setIsLoading(false);
      return;
    }

    logger.log("Fetching conversations for user:", currentUser.uid);
    const userChatsRef = ref(realtimeDb, `userChats/${currentUser.uid}`);

    try {
      setIsLoading(true);
      const snapshot = await get(userChatsRef);
      logger.log("User chats:", snapshot.val());

      if (snapshot.exists()) {
        const chatIds = Object.keys(snapshot.val());
        const conversationsPromises = chatIds.map(async chatId => {
          const chatSnapshot = await get(ref(realtimeDb, `chats/${chatId}`));
          const chatData = chatSnapshot.val();
          if (!chatData) return null;

          const otherUserId = Object.keys(chatData.participants).find(id => id !== currentUser.uid);

          return {
            id: chatSnapshot.key,
            otherUserId,
            lastMessage: chatData.lastMessage?.text || 'No messages yet'
          };
        });

        let conversationsList = (await Promise.all(conversationsPromises)).filter(Boolean);

        // Batch fetch user names
        const userIds = conversationsList.map(conv => conv.otherUserId);
        const userNames = await userDataService.batchGetUserFirstNames(userIds);

        conversationsList = conversationsList.map(conv => ({
          ...conv,
          name: userNames.find(u => u.userId === conv.otherUserId)?.firstName || 'Unknown'
        }));

        logger.log("Conversations list:", conversationsList);
        setConversations(conversationsList);
      } else {
        logger.log("No chats found");
        setConversations([]);
      }
    } catch (error) {
      logger.error("Error fetching conversations:", error);
      setError("Failed to load conversations. Please try again later.");
      sendErrorToTelegram('Error in fetchConversations in MessagesPage', { error: error.message });
    } finally {
      setIsLoading(false);
    }
  }, [currentUser]);

  // Fetch messages for selected conversation
  useEffect(() => {
    if (selectedConversation) {
      const messagesRef = ref(realtimeDb, `chats/${selectedConversation.id}/messages`);
      const unsubscribe = onValue(messagesRef, (snapshot) => {
        const data = snapshot.val();
        if (data) {
          const messageList = Object.entries(data).map(([key, value]) => ({
            id: key,
            ...value,
          })).sort((a, b) => a.timestamp - b.timestamp);
          setMessages(messageList);
        } else {
          setMessages([]);
        }
      }, (error) => {
        logger.error("Error fetching messages:", error);
        setError("Failed to load messages. Please try again later.");
        sendErrorToTelegram('Error fetching messages in MessagesPage', { error: error.message });
      });

      return () => off(messagesRef);
    }
  }, [selectedConversation]);

  // Initial fetch of conversations
  useEffect(() => {
    fetchConversations();
  }, [fetchConversations]);

  // Handle sending a new message
  const handleSendMessage = (e) => {
    e.preventDefault();
    if (newMessage.trim() && selectedConversation) {
      const chatRef = ref(realtimeDb, `chats/${selectedConversation.id}`);
      const newMessageRef = push(ref(realtimeDb, `chats/${selectedConversation.id}/messages`));

      const messageData = {
        text: newMessage,
        senderId: currentUser.uid,
        senderName: currentUser.displayName || 'Anonymous',
        timestamp: Date.now(),
      };

      const updates = {};
      updates[`/messages/${newMessageRef.key}`] = messageData;
      updates['/lastMessage'] = {
        text: newMessage,
        timestamp: messageData.timestamp
      };

      update(chatRef, updates)
        .then(() => {
          logger.log("Message sent successfully");
          setNewMessage('');
        })
        .catch((error) => {
          logger.error("Error sending message:", error);
          setError("Failed to send message. Please try again.");
          sendErrorToTelegram('Error sending message in MessagesPage', { error: error.message });
        });
    }
  };

  // Filter conversations based on search term
  const filteredConversations = conversations.filter((conv) =>
    conv.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Handle successful authentication
  const handleAuthSuccess = () => {
    setShowAuthModal(false);
    fetchConversations();
  };

  // Early return for auth modal
  if (showAuthModal) {
    return (
      <AuthModal
        mode="signin"
        onClose={() => setShowAuthModal(false)}
        onAuthSuccess={handleAuthSuccess}
        customMessage={messagesPageTranslations.authPrompt}
      />
    );
  }

  // Early return for loading state
  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <Loader className="animate-spin text-blue-500" size={48} />
        <span className="ml-2">{messagesPageTranslations.loadingConversations}</span>
      </div>
    );
  }

  // Early return for error state
  if (error) {
    return <div className="text-red-500 text-center p-4">{error}</div>;
  }

  return (
    <div className="flex flex-col h-full bg-gray-100">
      {/* Tab Navigation */}
      <nav className="flex bg-white border-b border-gray-200">
        <button
          onClick={() => setActiveTab('conversations')}
          className={`flex-1 py-3 px-4 text-sm font-medium ${
            activeTab === 'conversations'
              ? 'text-blue-600 border-b-2 border-blue-600'
              : 'text-gray-500'
          }`}
        >
          {messagesPageTranslations.tabs.conversations}
        </button>
        <button
          onClick={() => setActiveTab('chat')}
          className={`flex-1 py-3 px-4 text-sm font-medium ${
            activeTab === 'chat'
              ? 'text-blue-600 border-b-2 border-blue-600'
              : 'text-gray-500'
          }`}
          disabled={!selectedConversation}
        >
          {messagesPageTranslations.tabs.chat}
        </button>
      </nav>
  
      {/* Content Area */}
      <div className="flex-grow overflow-hidden">
        {activeTab === 'conversations' ? (
          <div className="h-full flex flex-col">
            {/* Search Bar */}
            <div className="p-4">
              <div className="relative">
                <input
                  type="text"
                  placeholder={messagesPageTranslations.searchPlaceholder}
                  className="w-full pl-10 pr-4 py-2 bg-gray-200 rounded-full focus:outline-none focus:ring-2 focus:ring-blue-500 focus:bg-white"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
                <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500" size={20} />
              </div>
            </div>
  
            {/* Conversation List */}
            {isLoading ? (
              <div className="flex justify-center items-center h-full">
                <Loader className="animate-spin text-blue-500" size={48} />
                <span className="ml-2">{messagesPageTranslations.loadingConversations}</span>
              </div>
            ) : error ? (
              <div className="text-red-500 text-center p-4">{error}</div>
            ) : conversations.length === 0 ? (
              <div className="text-center py-12 bg-white rounded-lg shadow">
                <MessageSquare className="mx-auto text-gray-400 mb-4" size={48} />
                <p className="text-xl text-gray-600">{messagesPageTranslations.noConversations}</p>
              </div>
            ) : (
              <ul className="flex-grow overflow-y-auto">
                {conversations.filter(conv => 
                  conv.name.toLowerCase().includes(searchTerm.toLowerCase())
                ).map((conv) => (
                  <li
                    key={conv.id}
                    className="hover:bg-gray-50 cursor-pointer transition duration-150 ease-in-out border-b border-gray-200"
                    onClick={() => {
                      setSelectedConversation(conv);
                      setActiveTab('chat');
                    }}
                  >
                    <div className="flex items-center p-4">
                      <div className="w-12 h-12 bg-gray-300 rounded-full mr-4 flex items-center justify-center">
                        <MessageSquare size={24} className="text-gray-600" />
                      </div>
                      <div className="flex-grow min-w-0">
                        <h3 className="text-base font-semibold text-gray-900 truncate">{conv.name}</h3>
                        <p className="text-sm text-gray-500 truncate">{conv.lastMessage}</p>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            )}
          </div>
        ) : (
          <div className="h-full flex flex-col">
            {selectedConversation && (
              <>
                {/* Chat Header */}
                <div className="bg-white p-4 border-b flex items-center">
                  <button onClick={() => setActiveTab('conversations')} className="mr-3">
                    <ArrowLeft size={24} />
                  </button>
                  <h2 className="font-semibold">{selectedConversation.name}</h2>
                </div>
  
                {/* Messages */}
                <div className="flex-grow overflow-y-auto p-4">
                  {messages.map((message) => (
                    <div
                      key={message.id}
                      className={`mb-4 ${
                        message.senderId === currentUser.uid ? 'text-right' : 'text-left'
                      }`}
                    >
                      <div
                        className={`inline-block p-3 rounded-lg ${
                          message.senderId === currentUser.uid
                            ? 'bg-blue-500 text-white'
                            : 'bg-gray-200'
                        }`}
                      >
                        {message.text}
                      </div>
                      <div className="text-xs text-gray-500 mt-1">
                        {new Date(message.timestamp).toLocaleString()}
                      </div>
                    </div>
                  ))}
                </div>
  
                {/* Message Input */}
                <form onSubmit={handleSendMessage} className="p-4 bg-white border-t">
                  <div className="flex items-center">
                    <input
                      type="text"
                      value={newMessage}
                      onChange={(e) => setNewMessage(e.target.value)}
                      placeholder={chatModalTranslations.inputPlaceholder}
                      className="flex-grow mr-2 p-2 border rounded-full bg-gray-100"
                    />
                    <button
                      type="submit"
                      className="bg-blue-500 text-white p-2 rounded-full hover:bg-blue-600"
                    >
                      <Send size={20} />
                    </button>
                  </div>
                </form>
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default MessagesPage;