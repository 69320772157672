import React, { useState } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { addDoc, collection, serverTimestamp } from 'firebase/firestore';
import { db } from '../firebase';
import { feedbackTranslations } from '../translations';
import { Loader } from 'lucide-react';
import {logger} from '../utils/logger';

const FeedbackForm = () => {
  const [feedback, setFeedback] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const { currentUser } = useAuth();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setError('');
    setSuccessMessage('');

    try {
      // Validate input
      if (feedback.trim().length < 10) {
        throw new Error(feedbackTranslations.errors.tooShort);
      }

      // Create feedback document
      const feedbackData = {
        content: feedback.trim(),
        createdAt: serverTimestamp(),
        userId: currentUser ? currentUser.uid : 'anonymous',
        userEmail: currentUser ? currentUser.email : 'anonymous'
      };

      // Add to Firestore
      await addDoc(collection(db, 'feedback'), feedbackData);

      logger.log('Feedback submitted successfully');
      setSuccessMessage(feedbackTranslations.successMessage);
      setFeedback(''); // Clear the form
    } catch (err) {
      logger.error('Error submitting feedback:', err);
      setError(err.message || feedbackTranslations.errors.submission);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="max-w-2xl mx-auto p-4">
      <h1 className="text-3xl font-bold mb-4">{feedbackTranslations.title}</h1>
      <p className="mb-4">{feedbackTranslations.description}</p>
      
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label htmlFor="feedback" className="block text-sm font-medium text-gray-700">
            {feedbackTranslations.feedbackLabel}
          </label>
          <textarea
            id="feedback"
            value={feedback}
            onChange={(e) => setFeedback(e.target.value)}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            rows="4"
            required
          ></textarea>
        </div>
        
        {error && (
          <div className="text-red-500">{error}</div>
        )}
        
        {successMessage && (
          <div className="text-green-500">{successMessage}</div>
        )}
        
        <button
          type="submit"
          disabled={isSubmitting}
          className={`px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition duration-300 ${
            isSubmitting ? 'opacity-50 cursor-not-allowed' : ''
          }`}
        >
          {isSubmitting ? (
            <>
              <Loader className="animate-spin inline-block mr-2" size={16} />
              {feedbackTranslations.submitting}
            </>
          ) : (
            feedbackTranslations.submitButton
          )}
        </button>
      </form>
    </div>
  );
};

export default FeedbackForm;