import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import ProfilePage from './pages/ProfilePage';
import SearchPage from './pages/SearchPage';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsOfService from './pages/TermsOfService';
import AboutUs from './pages/AboutUs';
import { AuthProvider } from './contexts/AuthContext';
import Layout from './components/Layout';
import MessagesPage from './pages/MessagesPage';
import FeedbackForm from './components/FeedbackForm';
import TestErrorLogger from './components/TestErrorLogger';

function App() {
  return (
    <AuthProvider>
      <Router>
        <Layout>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/profile/create" element={<ProfilePage isCreating={true} />} />
            <Route path="/profile/:userId" element={<ProfilePage />} />
            <Route path="/profile/:userId/edit" element={<ProfilePage isEditing={true} />} />
            <Route path="/search" element={<SearchPage />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms-of-service" element={<TermsOfService />} />
            <Route path="/messages" element={<MessagesPage />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/feedback" element={<FeedbackForm />} />
          </Routes>
        </Layout>
      </Router>
    </AuthProvider>
  );
}

export default App;